<template>
  <b-container>
    <h2>Access level editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ editId ? 'Edit' : 'New'}} access level</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group description label="Access level name*">
            <b-form-input
              id="acc_name"
              v-model="access_level_name"
              :state="$v.access_level_name.$dirty ? !$v.access_level_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group description label="Primary role*">
            <b-form-select
              id="acc_primary"
              v-model="access_primary_role"
              :options="primaryRoleOptions"
              :state="$v.access_primary_role.$dirty ? !$v.access_primary_role.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="acc_primary">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group description label="TIM Templates*">
            <multiselect
              v-model="access_tim_templates"
              :multiple="true"
              track-by="id"
              label="name"
              :options="timOptions"
            ></multiselect>
          </b-form-group>
  
          <b-form-group description label="Non-AD Templates*">
            <multiselect
              v-model="access_non_ad_templates"
              :multiple="true"
              track-by="id"
              label="name"
              :options="nonADOptions"
            ></multiselect>
          </b-form-group>
          
          <b-form-checkbox id="checkbox-1" v-model="hidden" name="hidden">Hidden</b-form-checkbox>

          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing access levels</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="access_level"
    >
      <template slot="cell(tim_templates)" slot-scope="row">
        <b-badge
          v-for="temp in row.value"
          :key="temp.name"
          variant="info"
          class="m-1 p-1"
        >{{ `${temp.name} (${temp.profile})` }}</b-badge>
      </template>
  
      <template slot="cell(non_ad_templates)" slot-scope="row">
        <b-badge
          v-for="temp in row.value"
          :key="temp.name"
          variant="info"
          class="m-1 p-1"
        >{{ `${temp.name} (${temp.profile})` }}</b-badge>
      </template>
      
      <template slot="cell(hidden)" slot-scope="row">
        <span v-if="row.item.hidden" class="text-danger">No</span>
        <span v-else class="text-success">Yes</span>
      </template>

      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import { FormSelect } from "../../_helpers/models";
import WarningText from "../WarningText";
export default {
  mixins: [validationMixin],
  created() {
    this.getAccessLevels();
    this.getPrimaryRoles();
    this.getTimTemplates();
    this.getNonADTemplates();
  },
  data() {
    return {
      show: true,

      hidden: false,
      access_level_name: "",
      access_primary_role: "",
      access_tim_templates: [],
      access_non_ad_templates: [],

      tableData: [],
      tableFields: [
        {
          key: "access_level",
          sortable: true
        },
        {
          key: "primary_role_name",
          sortable: true
        },
        {
          key: "primary_role_code",
          sortable: true
        },
        {
          key: "tim_templates",
          sortable: true,
          label: "TIM templates name (profile)"
        },
        {
          key: "non_ad_templates",
          sortable: true,
          label: "Non-AD templates name (profile)"
        },
        {
          key: "hidden",
          sortable: true,
          label: "Visible"
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listPrimaryRoles: [],
      listAccessLevels: [],
      listOrganizationUnits: [],
      listTimTemplates: [],
      listNonADTemplates: [],
      listManagers: [],

      loading: true,
      defaultOption: { value: "", text: "Select option" },
      filter: "",
      editId: null
    };
  },
  validations: {
    access_level_name: {
      required
    },
    access_primary_role: {
      required
    }
  },
  computed: {
    postData() {
      return {
        access_level_name: this.access_level_name,
        primary_role_id: this.access_primary_role,
        tim_templates: this.access_tim_templates.concat(this.access_non_ad_templates).map(t => t.id),
        hidden: this.hidden
      };
    },
    postUrl() {
      return this.editId
        ? `/primary_role/access_level/edit/${this.editId}`
        : "/primary_role/access_level/create";
    },
    successMsg() {
      return this.editId ? "Access level modification OK" : "New access level added";
    },
    formTitle() {
      return this.editId ? "Edit access level" : "New access level";
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },

    timOptions() {
      return this.listTimTemplates.map(t => ({ name: t.name, id: t.id }));
    },

    nonADOptions() {
      return this.listNonADTemplates.map(t => ({ name: t.name, id: t.id }));
    },

    primaryRoleOptions() {
      const options = this.listPrimaryRoles
        .map(r => ({
          value: r.id,
          text: `${r.name} (${r.code}) (OU: ${r.organizational_unit_name})`
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
      return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getAccessLevels();
        })
        .catch(error => {
          console.log(error.response);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.access_level_name = "";
      this.access_primary_role = "";
      this.access_tim_templates = [];
      this.access_non_ad_templates = [];
      this.hidden = false;

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getPrimaryRoles() {
      axios.get("/primary_role/list").then(response => {
        this.listPrimaryRoles = response.data.data.map(role => ({
          ...role,
          manager: `${role.manager_full_name} (${role.manager_username})`
        }));
      });
    },

    getAccessLevels() {
      this.loading = true;

      axios
        .get("/data/primary_role/access_level/creation")
        .then(response => {
          this.loading = false;
          this.tableData = response.data.data.primary_role_access_levels;
        })
        .catch(error => console.log("Failed to get access levels: " + error));
    },

    getTimTemplates() {
      axios.get("/tim_template/list/tim").then(response => {
        this.listTimTemplates = response.data.data;
      });
    },

    getNonADTemplates() {
        axios.get("/tim_template/list/non_ad").then(response => {
            this.listNonADTemplates = response.data.data;
        });
    },
    
    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);

      this.access_level_name = row.access_level;
      this.access_primary_role = row.primary_role_id;
      this.access_tim_templates = row.tim_templates.map(t => ({
        id: t.id,
        name: t.name
      }));
      this.access_non_ad_templates = row.non_ad_templates.map(t => ({
        id: t.id,
        name: t.name
      }));
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>
