<template>
  <b-button :class="btnClass" :variant="btnVariant" :size="getSize" @click="onExport">
    {{ label }}
    <font-awesome-icon :icon="getIcon" class="ml-1"></font-awesome-icon>
  </b-button>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    label: String,
    btnClass: [String, Object, Array],
    size: "xs" | "sm" | "xl" | "lg",
    data: Array,
    btnVariant: String,
    type: String,
    icon: String,
    isTimTemplate: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    onExport() {
      let url = this.isTimTemplate ? `/tim_template/export/${this.type}` : `/identity/export/${this.type}`;
      axios
        .post(
          url,
          this.type === "csv"
            ? {} // csv export always returns all revisions
            : {
                revisions: this.data.map(item => ({
                  version_no: item.version_no,
                  master_id: item.master_id
                }))
              },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          this.loading = false;
          console.log(response);
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;

          const headerLine = response.headers["content-disposition"];
          const startFileNameIdx = headerLine.indexOf('"') + 1;
          const endFileNameIdx = headerLine.lastIndexOf('"');
          const filename = headerLine.substring(
            startFileNameIdx,
            endFileNameIdx
          );
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
    }
  },
  computed: {
    getSize() {
      return this.size || "";
    },
    getIcon() {
      return this.icon || "download";
    }
  }
};
</script>
