<template>
  <div>
    <b-row v-for="attr in reqAttributes" :key="attr.key">
      <b-col md="3" sm="12">
        <strong>{{ attr.name }}</strong>:
      </b-col>
      <b-col md="7" sm="12">
        <div v-html="printValue(attr)"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { IdentityRequestAttributes } from "../_helpers/models";
import { mapState } from "vuex";
export default {
  data() {
    return {
      reqAttributes: IdentityRequestAttributes
    };
  },
  props: {
    req: Object
  },
  computed: {
    ...mapState("settings", ["dateFormat"])
  },
  methods: {
    printValue(attr) {
      return attr.hasOwnProperty("printer")
        ? attr.printer(this.req[attr.key], { format: this.dateFormat })
        : this.req[attr.key] || "-";
    }
  }
};
</script>