<template>
  <div>
    <b-row>
      <b-col md="2" sm="12" class="pr-0">
        <i>Before:</i>
      </b-col>
      <b-col md="7" sm="12">
        <user-details-value :value="oldVal" :date="date" :extraClass="['highlight','old']"></user-details-value>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="12" class="pr-0">
        <i>After:</i>
      </b-col>
      <b-col md="7" sm="12">
        <user-details-value :value="newVal" :date="date" :extraClass="['highlight','new']"></user-details-value>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UserDetailsValue from "./UserDetailsValue";
export default {
  props: {
    oldVal: Object | String,
    newVal: Object | String,
    date: Boolean,
    list: Boolean
  },
  components: {
    UserDetailsValue
  }
};
</script>