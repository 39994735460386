export const identities = {
  namespaced: true,
  state: {
    mainTableItems: [],
    loading: true,
    searchResults: [],
    search: false,
    filterSelectOptions: false,
    defaultOption: [{ value: "", text: "Select option" }]
  },
  mutations: {
    ADD_IDENTITIES(state, items) {
      state.mainTableItems = items;
    },

    SET_OPTIONS(state, options) {
      state.filterSelectOptions = options;
    },
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results;
      state.search = true;
    },
    RESET_SEARCH(state) {
      state.search = false;
      state.searchResults = state.mainTableItems;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    addIdentities({ dispatch, commit }, identities) {
      commit("ADD_IDENTITIES", identities);
    },
    stopLoading({ commit }) {
      commit("SET_LOADING", false);
    },
    startLoading({ commit }) {
      commit("SET_LOADING", true);
    }
  },
  getters: {
    statusOptions: state => {
      return !state.filterSelectOptions
        ? []
        : state.filterSelectOptions.user_statuses.map(status => ({
            value: status.id,
            text: status.user_status
          }));
    },

    accessLevelOptions: state => {
      if (!state.filterSelectOptions) return [];
      const levels = [];
      const map = new Map();
      for (const role of state.filterSelectOptions.primary_roles) {
        for (const level of role.access_levels) {
          if (!map.has(level.id)) {
            map.set(level.id, true); // set any value to map
            levels.push({
              value: level.id,
              text: level.name
            });
          }
        }
      }
      return state.defaultOption.concat(
        levels.sort((a, b) => a.text.localeCompare(b.text))
      );
    },

    primaryRoleOptions: state => {
      return !state.filterSelectOptions
        ? []
        : state.defaultOption.concat(
            state.filterSelectOptions.primary_roles
              .map(r => ({
                value: r.id,
                text: `${r.name} (OU: ${r.organizational_unit_name})`
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          );
    },

    employmentTypeOptions: state => {
      return !state.filterSelectOptions
        ? []
        : state.defaultOption.concat(
            state.filterSelectOptions.employment_types.map(e => ({
              value: e.id,
              text: e.employment_type
            }))
          );
    },

    companyOptions: state => {
      return !state.filterSelectOptions
        ? []
        : state.defaultOption.concat(
            state.filterSelectOptions.companies
              .map(c => ({
                value: c.id,
                text: c.name
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          );
    },

    externalCompanyOptions: state => {
      return !state.filterSelectOptions
        ? []
        : state.defaultOption.concat(
            state.filterSelectOptions.external_companies
              .map(ex => ({
                value: ex.id,
                text: ex.name
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          );
    }
  }
};
