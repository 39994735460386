<template>
  <b-container>
    <h2>Organizational unit editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group label="Organization unit name*">
            <b-form-input
              id="org_name"
              v-model="org_unit_name"
              :state="$v.org_unit_name.$dirty ? !$v.org_unit_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="org_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Organization unit code*">
            <b-form-input
              id="org_code"
              v-model="org_unit_code"
              :state="$v.org_unit_code.$dirty ? !$v.org_unit_code.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="org_code">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Company*">
            <b-form-select
              id="org_company"
              v-model="company_id"
              :options="companyOptions"
              :state="$v.company_id.$dirty ? !$v.company_id.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="org_company">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Department*">
            <b-form-select
              id="org_dep"
              v-model="org_unit_department"
              :options="orgUnitDepartmentOptions"
              :state="$v.org_unit_department.$dirty ? !$v.org_unit_department.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="org_dep">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Cost center*">
            <b-form-input
              id="org_cc"
              v-model="org_unit_cost_center"
              :state="$v.org_unit_cost_center.$dirty ? !$v.org_unit_cost_center.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="org_cc">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing organizational units</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" class="w-100" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { FormSelect } from "../../_helpers/models";
import FilterInput from "../Filter";
import WarningText from "../WarningText";
export default {
  mixins: [validationMixin],
  created() {
    this.getDepartments();
    this.getOrgUnits();
    this.getCompanies();
  },
  data() {
    return {
      org_unit_name: "",
      org_unit_code: "",
      org_unit_department: "",
      org_unit_cost_center: "",
      company_id: "",

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "code",
          sortable: true
        },
        {
          key: "department",
          sortable: true
        },
        {
          key: "company",
          sortable: true
        },
        {
          key: "cost_center",
          sortable: true
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      defaultOption: { value: "", text: "Select option" },
      listDepartments: [],
      listCostCenters: [],
      listOrgUnits: [],
      listCompanies: [],
      loading: false,
      filter: "",
      editId: null
    };
  },
  validations: {
    org_unit_name: {
      required
    },
    org_unit_code: {
      required
    },
    org_unit_department: {
      required
    },
    org_unit_cost_center: {
      required
    },
    company_id: {
      required
    }
  },
  computed: {
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },
    postData() {
      return {
        name: this.org_unit_name,
        code: this.org_unit_code,
        department_id: this.org_unit_department,
        cost_center_code: this.org_unit_cost_center,
        company_id: this.company_id
      };
    },
    postUrl() {
      return this.editId
        ? `/organizational_unit/edit/${this.editId}`
        : "/organizational_unit/create";
    },
    successMsg() {
      return this.editId
        ? "Organization unit modification OK"
        : "New organization unit added";
    },
    formTitle() {
      return this.editId ? "Edit organizational unit" : "Add new organizational unit";
    },
    orgUnitCostCenterOptions() {
      const options = this.listCostCenters.map(cc => ({
        text: cc.code,
        value: cc.id
      }));
      return [this.defaultOption, ...options];
    },
    orgUnitDepartmentOptions() {
      const options = this.listDepartments.map(dep => ({
        text: `${dep.name} (${dep.code})`,
        value: dep.id
      }));
      return [this.defaultOption, ...options];
    },
    companyOptions() {
      const options = this.listCompanies.map(comp => ({
        text: `${comp.name} (${comp.code})`,
        value: comp.id
      }));
      return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getOrgUnits();
        })
        .catch(error => {
          console.log(error);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);
      this.org_unit_name = row.name;
      this.org_unit_code = row.code;
      this.org_unit_department = row.department_id;
      this.org_unit_cost_center = row.cost_center_code;
      this.company_id = row.company_id;
    },

    resetForm() {
      this.$v.$reset();
      this.org_unit_name = "";
      this.org_unit_code = "";
      this.org_unit_cost_center = "";
      this.org_unit_department = "";
      this.company_id = "";

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getDepartments() {
      axios.get("department/list").then(response => {
        this.listDepartments = response.data.data;
      });
    },

    getCompanies() {
      axios.get("company/list").then(response => {
        this.listCompanies = response.data.data;
      });
    },

    getOrgUnits() {
      this.loading = true;

      axios.get("organizational_unit/list").then(response => {
        this.listOrgUnits = response.data.data.map(org => ({
          ...org,
          department: `${org.department_name} (${org.department_code})`,
          cost_center: `${org.cost_center_code}`,
          company: `${org.company_name} (${org.company_code})`
        }));

        this.loading = false;
        this.tableData = this.listOrgUnits;
      });
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>