<template>
  <b-container>
    <h2>External company editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group label="Company name*">
            <b-form-input
              id="comp_name"
              v-model="company_name"
              :state="$v.company_name.$dirty ? !$v.company_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="comp_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Official company name*">
            <b-form-input
              id="official_name"
              v-model="official_name"
              :state="$v.official_name.$dirty ? !$v.official_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="official_name">This field is required</b-form-invalid-feedback>
          </b-form-group>

          
          <b-form-group label="Manager name*">
            <b-form-select
              id="manager_name"
              :options="managerOptions"
              v-model="manager_id"
              :state="$v.manager_id.$dirty ? !$v.manager_id.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-select>
            <b-form-invalid-feedback id="manager_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group label="Tax ID*">
            <b-form-input
              id="tax_id"
              v-model="tax_id"
              :state="$v.tax_id.$dirty ? !$v.tax_id.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="tax_id">This field is required</b-form-invalid-feedback>
          </b-form-group>
          
          <b-form-checkbox v-model="hidden" name="hidden">Hidden</b-form-checkbox>
          
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing external companies</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(record_status)" slot-scope="row">
        <span v-if="row.item.record_status === 'Live'" class="text-success">Live</span>
        <span v-else class="text-danger">Pending</span>
      </template>
      
      <template slot="cell(hidden)" slot-scope="row">
        <span v-if="row.item.hidden" class="text-danger">No</span>
        <span v-else class="text-success">Yes</span>
      </template>

      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import { FormSelect } from "../../_helpers/models";
import WarningText from "../WarningText";
export default {
  created() {
    this.getExternalCompanies();
    this.loadManagers();
  },
  data() {
    return {
      show: true,

      company_name: "",
      official_name: "",
      comment: "",
      manager_id: "",
      tax_id: "",
      company_code: "",
      hidden: false,

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "official_name",
          sortable: true,
        },
        {
          key: "comment",
          sortable: true,
        },
        {
          key: "manager",
          sortable: true,
        },
        {
          key: "tax_id",
          sortable: true,
          label: "Tax ID"
        },
        {
          key: "hidden",
          sortable: true,
          label: "Visible"
        },
        {
          key: "record_status",
          sortable: true,
          label: "Status"
        },
        {
          key: "action",
          sortable: false,
          label: "Action"
        }
      ],

      listTimTemplates: [],
      listCustomRoles: [],
      listDataOwners: [],
      listManagers: [],
      
      loading: true,
      defaultOption: { value: "", text: "Select option" },
      filter: "",
      editId: null
    };
  },
  validations: {
    company_name: {
      required
    },
    official_name: {
      required
    },
    manager_id: {
      required
    },
    tax_id: {
      required
    }
  },
  computed: {
    postData() {
      return {
        name: this.company_name,
        official_name: this.official_name,
        comment: this.comment,
        manager_id: this.manager_id,
        master_id: this.master_id,
        tax_id: this.tax_id,
        hidden: this.hidden
      };
    },
    postUrl() {
      return this.editId
        ? `/external_company/edit/${this.editId}/${this.master_id}`
        : "/external_company/create";
    },
    successMsg() {
      return this.editId
        ? "External company modification OK"
        : "New external company added";
    },
    formTitle() {
      return this.editId ? "Edit external company" : "New external company";
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },
    managerOptions() {
        const options = this.listManagers.map(m => ({
            value: m.id,
            text: `${m.full_name} (${m.username})`,
            org: m.organizational_unit_id
        }));
        return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getExternalCompanies();
        })
        .catch(error => {
            console.log(error.response.data);
            const errors = error.response.data.errors;
            // show error toast for each error message
            Object.keys(errors).forEach(key => {
                errors[key].forEach(err => {
                    this.$bvToast.toast(err, {
                        title: "Error",
                        autoHideDelay: 6000,
                        variant: "danger",
                        solid: true
                    });
                });
            });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.company_name = "";
      this.official_name = "";
      this.comment = "";
      this.manager_id = "";
      this.tax_id = "";
      this.hidden = false;
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getExternalCompanies() {
      this.loading = true;
      
      axios
        .get("/external_company/list")
        .then(response => {
          this.listCompanies = response.data.data.map(company => ({
            ...company,
            manager: `${company.manager_full_name} (${company.manager_username})`
          }));
          
          this.loading = false;
          this.tableData = this.listCompanies;
        })
        .catch(error => console.log("Failed to get external companies: " + error));
    },

    loadManagers() {
        axios.get("/external_company/managers").then(response => {
            this.listManagers = response.data.data.managers;
        });
    },

    handleEdit(row) {
      window.scrollTo(0, 0);

      this.master_id = row.master_id;
      this.editId = row.id;
      this.company_name = row.name;
      this.official_name = row.official_name;
      this.comment = row.comment;
      this.manager_id = row.manager_id;
      this.tax_id = row.tax_id;
      this.hidden = row.hidden;
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>
