<template>
  <p>
    <font-awesome-icon icon="exclamation-triangle" size="lg" class="mr-2 text-danger"></font-awesome-icon>
    {{ warningText }}
  </p>
</template>
<script>
import { prettyConcat } from "../_helpers/common";
export default {
  computed: {
    warningText() {
      const allowed = prettyConcat(this.$route.meta.authorize);
      return `This page is restricted to ${allowed} users only.`;
    }
  }
};
</script>