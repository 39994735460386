<template>
  <b-container>
    <h2>Primary role editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group description label="Primary role name*">
            <b-form-input
              v-model="primary_role"
              :state="$v.primary_role.$dirty ? !$v.primary_role.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group description label="Primary role code*">
            <b-form-input
              v-model="primary_role_code"
              :state="$v.primary_role_code.$dirty ? !$v.primary_role_code.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group description label="Department*">
            <b-form-select
              v-model="department"
              :options="primaryDepartmentOptions"
              :state="$v.department.$dirty ? !$v.department.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-select>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group description label="Organizational unit*">
            <b-form-select
              v-model="organization"
              :options="organizationOptions"
              :state="$v.organization.$dirty ? !$v.organization.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-select>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group description label="Manager name*">
            <b-form-select
              :options="managerOptions"
              v-model="manager_id"
              :state="$v.manager_id.$dirty ? !$v.manager_id.$error : null"
              aria-describedby="input-2-live-feedback"
            ></b-form-select>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group v-if="!editId" description label="Access levels*">
            <multiselect
              v-model="access_levels"
              :multiple="true"
              track-by="id"
              label="name"
              :options="accesLevelOptions"
              aria-describedby="input-2-live-feedback"
            ></multiselect>
            <small v-if="$v.access_levels.$error" class="text-danger">This field is required</small>
          </b-form-group>
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing primary roles</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(access_levels)" slot-scope="row">
        <b-badge
          v-for="level in row.value"
          :key="level.name"
          variant="info"
          class="m-1 p-1"
        >{{ level.name }}</b-badge>
      </template>

      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import { prettyConcat, deCapitalize } from "../../_helpers";
import WarningText from "../WarningText";
export default {
  mixins: [validationMixin],
  created() {
    this.getPrimaryRoles();
    this.loadPrimaryOptions();
    this.loadPrimaryOptions();
  },
  data() {
    return {
      show: true,
      primary_role: "",
      primary_role_code: "",
      department: "",
      organization: "",
      manager_id: "",
      access_levels: [],

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "code",
          sortable: true
        },
        {
          key: "department",
          sortable: true
        },
        {
          key: "organizational_unit",
          label: "Org. unit",
          sortable: true
        },
        {
          key: "manager",
          sortable: true
        },
        {
          key: "access_levels"
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listPrimaryRoles: [],
      listAccessLevels: [],
      listOrganizationUnits: [],
      listManagers: [],
      defaultOption: { value: "", text: "Select option" },
      loading: true,

      filter: "",
      editId: null
    };
  },
  validations: {
    primary_role: {
      required
    },
    primary_role_code: {
      required
    },
    department: {
      required
    },
    organization: {
      required
    },
    manager_id: {
      required
    },
    access_levels: {
      required
    }
  },
  computed: {
    warningText() {
      const allowed = prettyConcat(this.$route.meta.authorize);
      return `This page is restricted to ${allowed} users only.`;
    },
    postData() {
      return {
        name: this.primary_role,
        code: this.primary_role_code,
        organizational_unit_id: this.organization,
        manager_id: this.manager_id,
        access_levels: this.access_levels.map(al => al.id)
      };
    },
    postUrl() {
      return this.editId ? `/primary_role/edit/${this.editId}` : "/primary_role/create";
    },
    successMsg() {
      return this.editId ? "Primary role modification OK" : "New primary role added";
    },
    formTitle() {
      return this.editId ? "Edit primary role" : "New primary role";
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },
    accesLevelOptions() {
      return this.listAccessLevels.map(a => ({ id: a.id, name: a.name }));
    },
    organizationOptions() {
      const orgUnits = this.listOrganizationUnits
        // filter org units if department is selected
        .filter(unit => (this.department ? unit.department_id === this.department : true))
        .map(o => ({
          value: o.id,
          text: `${o.name} (${o.code})`
        }));

      return [this.defaultOption, ...orgUnits];
    },

    primaryDepartmentOptions() {
      const departmentsArr = this.listOrganizationUnits.reduce((options, org) => {
        if (!options.find(opt => opt.value === org.department_id)) {
          options.push({
            value: org.department_id,
            text: `${org.department_name} (${org.department_code})`
          });
        }
        return options;
      }, []);
      return [this.defaultOption, ...departmentsArr];

      //console.log(JSON.stringify(found));
      //return found ? `${found.department_name} (${found.department_code})` : "";
    },
    managerOptions() {
      const options = this.listManagers.map(m => ({
        value: m.id,
        text: `${m.full_name} (${m.username})`,
        org: m.organizational_unit_id
      }));
      return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getPrimaryRoles();
        })
        .catch(error => {
          console.log(error.response);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.primary_role = "";
      this.primary_role_code = "";
      this.organization = "";
      this.manager_id = "";
      this.access_levels = [];
      this.department = "";

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getPrimaryRoles() {
      this.loading = true;

      axios.get("/primary_role/list").then(response => {
        this.listPrimaryRoles = response.data.data.map(role => ({
          ...role,
          department: `${role.department_name} (${role.department_code})`,
          organizational_unit: `${role.organizational_unit_name} (${role.organizational_unit_code})`,
          manager: `${role.manager_full_name} (${role.manager_username})`
        }));

        this.loading = false;
        this.tableData = this.listPrimaryRoles;
      });
    },

    loadPrimaryOptions() {
      axios.get("/data/primary_role/creation").then(response => {
        this.listAccessLevels = response.data.data.access_levels;
        this.listOrganizationUnits = response.data.data.organizational_units;
        this.listManagers = response.data.data.managers;
        //this.loading = false;
      });
    },
    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);

      this.primary_role = row.name;
      this.primary_role_code = row.code;
      this.department = row.department_id;
      this.organization = row.organizational_unit_id;
      this.manager_id = row.manager_id;
      this.access_levels = row.access_levels.map(a => ({
        id: a.id,
        name: a.name
      }));
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>