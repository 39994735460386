import { authenticationService } from "../_services";
import { router } from "../_helpers/router";

const token = authenticationService.getToken;
const initialState = token
  ? { status: { loggedIn: true }, token: token, user: null }
  : { status: {}, user: null, token: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      //commit("LOGIN_REQUEST", { username });

      return authenticationService.login(username, password).then(
        token => {
          // save token to store
          commit("LOGIN_SUCCESS", token);
          router.push("/");
          // get the user data
          dispatch("setUserData");
        },
        error => {
          console.log("authentication module login error: " + JSON.stringify(error));
          commit("LOGIN_FAILURE", error);
        }
      );
    },
    logout({ commit }) {
      authenticationService.logout();
      commit("LOGOUT");
    },
    setUserData({ commit }) {
      authenticationService.getCurrentUserData().then(user => {
        commit("SET_USER_DATA", user);
      });
    }
  },
  mutations: {
    // LOGIN_REQUEST(state, token) {
    //   state.status = { loggingIn: true };
    //   state.token = token;
    // },
    LOGIN_SUCCESS(state, token) {
      state.status = { loggedIn: true };
      state.token = token;
    },
    LOGIN_FAILURE(state, error) {
      state.status = { unauth: true };
      state.token = null;
      state.user = null;
    },
    LOGOUT(state) {
      state.status = {};
      state.token = null;
      state.user = null;
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    }
  }
};
