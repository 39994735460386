<template>
  <div v-if="show" class="sizeindicatorhack" title="The current Bootstrap breakpoint being used">
    <span class="badge badge-dark d-inline-block d-sm-none">XS</span>
    <span class="badge badge-success d-none d-sm-inline-block d-md-none">SM</span>
    <span class="badge badge-danger d-none d-md-inline-block d-lg-none">MD</span>
    <span class="badge badge-info d-none d-lg-inline-block d-xl-none">LG</span>
    <span class="badge badge-warning d-none d-xl-inline-block">XL</span>
  </div>
</template>
<script>
export default {
  computed: {
    show() {
      return [1, "true"].includes(process.env.MIX_VUE_APP_SHOW_BS_SIZE);
    }
  }
};
</script>
<style scoped>
.sizeindicatorhack {
  position: fixed;
  z-index: 9999;
  top: 55px;
  left: 5px;
  cursor: help;
}
</style>>


