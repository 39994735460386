<template>
  <div>
    <b-row>
      <b-col md="3" sm="12">
        <strong>{{ name }}</strong>:
      </b-col>

      <b-col md="9" sm="12">
        <!-- if it is a modification request -->
        <template v-if="isModificationRequest">
          <!-- if access level changed show old vs new values -->
          <template v-if="value.changed">
            <b-row>
              <b-col md="2" sm="12" class="pr-0">
                <i>Before:</i>
              </b-col>
              <b-col md="7" sm="12">
                <user-details-value
                  :value="value.old"
                  :extraClass="['highlight','old']"
                  hasTimList
                  :timList="timListBefore"
                  hasNonAdList
                  :nonAdList="nonAdListBefore"
                ></user-details-value>
                <!-- <ul>
                  <small>
                    <li v-for="tim in timListBefore" :key="tim.id">
                      <tim :tim="tim"></tim>
                    </li>
                  </small>
                </ul>-->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" sm="12" class="pr-0">
                <i>After:</i>
              </b-col>
              <b-col md="7" sm="12">
                <user-details-value
                  :value="value.new"
                  :extraClass="['highlight','new']"
                  hasTimList
                  :timList="timListAfter"
                  hasNonAdList
                  :nonAdList="nonAdListAfter"
                ></user-details-value>
                <!-- <ul>
                  <small>
                    <li v-for="tim in timListAfter" :key="tim.id">
                      <tim :tim="tim"></tim>
                    </li>
                  </small>
                </ul>-->
              </b-col>
            </b-row>
          </template>

          <!-- access level not changed -->
          <template v-else>
            <user-details-value :value="value.value" hasTimList :timList="timList.kept" hasNonAdList :nonAdList="nonAdList.kept"></user-details-value>
            <!-- <ul>
              <small>
                <li v-for="tim in timList.kept" :key="tim.id">
                  <tim :tim="tim"></tim>
                </li>
              </small>
            </ul>-->
          </template>
        </template>

        <!-- not modification request -->
        <template v-else>
          <user-details-value :value="value" hasTimList :timList="timList" :nonAdList="nonAdList" hasNonAdList></user-details-value>
          <!-- <ul>
            <small>
              <li v-for="tim in timList" :key="tim.id">
                <tim :tim="tim"></tim>
              </li>
            </small>
          </ul>-->
        </template>
        
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Tim from "./TIMrow";
import UserDetailsValue from "./UserDetailsValue";
export default {
  props: {
    name: String,
    value: String | Object, // identitiy.access_level_name, obj or string depending on modification request or not
    timList: Array | Object, // depends on modification request or not
    nonAdList: Array | Object // depends on modification request or not
  },
  computed: {
    timListAfter() {
      return this.timList.added.concat(this.timList.kept);
    },
    timListBefore() {
      return this.timList.removed.concat(this.timList.kept);
    },
    nonAdListAfter() {
        return this.nonAdList.added.concat(this.nonAdList.kept);
    },
    nonAdListBefore() {
        return this.nonAdList.removed.concat(this.nonAdList.kept);
    },
    isModificationRequest() {
      return this.value.hasOwnProperty("changed");
    }
  },
  components: {
    Tim,
    UserDetailsValue
  }
};
</script>
