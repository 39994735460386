<template>
  <b-navbar toggleable="md" type="light" variant="light" sticky>
    <img src="/img/logo.png" height="32" class="d-inline-block align-top mr-2" alt="Logo" />
    <router-link :to="{ name: 'home' }">
      <b-navbar-brand>IDM Frontend Portal</b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{name: 'home'}">Search</b-nav-item>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template slot="button-content">Requests</template>
          <b-dropdown-item
            v-if="showNewRequestMenu"
            :to="{name: 'newIdentity'}"
            @click="resetFormProgress"
          >New Request</b-dropdown-item>
          <b-dropdown-item :to="{name: 'incomingIdentity'}">Incoming Requests</b-dropdown-item>
          <b-dropdown-item :to="{name: 'outgoingIdentity'}">Outgoing Requests</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item :to="{name: 'reports'}">Reports</b-nav-item>
        <b-nav-item v-if="showAdmin" :to="{name: 'admin'}">Admin</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template slot="button-content">
            <font-awesome-icon icon="user" class="mr-1"></font-awesome-icon>
            {{ name }}
          </template>
          <b-dropdown-item :to="{name: 'profile'}">Profile</b-dropdown-item>
          <b-dropdown-item :to="{name: 'settings'}">Settings</b-dropdown-item>
          <b-dropdown-item @click="handleLogout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { authenticationService } from "../_services";
import { mapState } from "vuex";
export default {
  created() {
    this.loadUser();
    this.loadUserSettings();
  },
  computed: {
    ...mapState("authentication", ["user"]),
    showAdmin() {
      return authenticationService.isAdmin() || authenticationService.isHR();
    },
    name() {
      return this.user ? this.user.full_name : "";
    },
    showNewRequestMenu() {
      return authenticationService.isHR() || authenticationService.isManager();
    }
  },
  methods: {
    // TODO move this call somewhere other than the navbar
    loadUser() {
      if (this.user) return;
      this.$store.dispatch("authentication/setUserData");
    },
    // TODO move this call somewhere other than the navbar
    loadUserSettings() {
      axios
        .get("settings/get")
        .then(response =>
          this.$store.dispatch("settings/setOptions", response.data.data)
        );
    },
    handleLogout() {
      this.$store.dispatch("authentication/logout");
      location.reload();
    },
    resetFormProgress() {
      this.$store.dispatch("requestform/resetFormProgress");
    }
  }
};
</script>