<template>
  <div>
    <b-row>
      <b-col md="3" sm="12">
        <strong>{{ label }}</strong>:
      </b-col>

      <b-col md="9">
        <!-- if it is a modification request -->
        <template v-if="isModificationRequest">
          <b-row>
            <b-col md="3" class="pr-0">
              <i>Added:</i>
            </b-col>
            <b-col md="9">
              <div v-if="roles.added.length">
                <user-details-value
                  v-for="role in roles.added"
                  :key="role.id"
                  :value="role.name"
                  :extraClass="['highlight','new']"
                  hasTimList
                  :timList="role.tim_templates"
                  hasNonAdList
                  :nonAdList="role.non_ad_templates"
                  :badge="role.approval_status"
                ></user-details-value>
              </div>
              <span v-else>-</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3" class="pr-0">
              <i>Removed:</i>
            </b-col>
            <b-col md="9">
              <div v-if="roles.removed.length">
                <user-details-value
                  v-for="role in roles.removed"
                  :key="role.id"
                  :value="role.name"
                  :extraClass="['highlight','old']"
                  hasTimList
                  :timList="role.tim_templates"
                  hasNonAdList
                  :nonAdList="role.non_ad_templates"
                  :badge="role.approval_status"
                ></user-details-value>
              </div>
              <span v-else>-</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3" class="pr-0">
              <i>Unchanged:</i>
            </b-col>
            <b-col md="9">
              <div v-if="roles.kept.length">
                <user-details-value
                  v-for="role in roles.kept"
                  :key="role.id"
                  :value="role.name"
                  hasTimList
                  :timList="role.tim_templates"
                  hasNonAdList
                  :nonAdList="role.non_ad_templates"
                  :badge="role.approval_status"
                ></user-details-value>
              </div>
              <span v-else>-</span>
            </b-col>
          </b-row>
        </template>

        <!-- not modification request -->
        <template v-else>
          <div v-if="roles.length">
            <user-details-value
              v-for="role in sortedRoles"
              :key="role.id"
              :value="role.name"
              hasTimList
              :timList="role.tim_templates"
              hasNonAdList
              :nonAdList="role.non_ad_templates"
              :badge="role.approval_status"
            ></user-details-value>
          </div>
          <span v-else>-</span>
        </template>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import Tim from "./TIMrow";
import UserDetailsValue from "./UserDetailsValue";
import { isObject } from "../_helpers";
export default {
  props: {
    label: String,
    roles: Array | Object // identitiy.custom_roles, obj or array depending on modification request or not
  },
  computed: {
    isModificationRequest() {
      return isObject(this.roles);
    },
    // todo on modification show only unchanged values if added and removed is empty
    rolesChanged() {
      return this.roles.added.length + this.roles.removed.length > 0;
    },
    sortedRoles() {
      return (
        !this.isModificationRequest &&
        this.roles.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  },
  components: {
    Tim,
    UserDetailsValue
  }
};
</script>

