<template>
  <div>
    <b-row v-for="attr in reqAttributes" :key="attr.key">
      <b-col md="3" sm="12">
        <strong>{{attr.name}}</strong>:
      </b-col>

      <b-col md="7" sm="12">
        <template v-if="isAttrChangeable(attr)">
          <template v-if="isAttrChanged(attr)">
            <b-row class="highlight-old">
              <b-col md="2">
                <i>Before:</i>
              </b-col>
              <b-col md="10">
                <span class="px-1">{{ getOld(attr) }}</span>
              </b-col>
            </b-row>
            <b-row class="highlight-new">
              <b-col md="2">
                <i>After:</i>
              </b-col>
              <b-col md="10">
                <span class="px-1">{{ getNew(attr) }}</span>
              </b-col>
            </b-row>
          </template>
          <div v-else>{{ getValue(attr) }}</div>
        </template>

        <div v-else>{{ req[attr.key] || '-' }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { IdentityRequestAttributes } from "../_helpers/models";
import { mapState } from "vuex";
export default {
  data() {
    return {
      reqAttributes: IdentityRequestAttributes
    };
  },
  props: {
    req: Object
  },
  computed: {
    ...mapState("settings", ["dateFormat"])
  },
  methods: {
    getOld(attr) {
      return attr.hasOwnProperty("printer")
        ? attr.printer(this.req[attr.key].old, {
            format: this.dateFormat
          })
        : this.req[attr.key].old || "-";
    },
    getNew(attr) {
      return attr.hasOwnProperty("printer")
        ? attr.printer(this.req[attr.key].new, {
            format: this.dateFormat
          })
        : this.req[attr.key].new || "-";
    },
    getValue(attr) {
      return attr.hasOwnProperty("printer")
        ? attr.printer(this.req[attr.key].value, {
            format: this.dateFormat
          })
        : this.req[attr.key].value || "-";
    },
    isAttrChanged(attr) {
      return this.req[attr.key].changed;
    },
    isAttrChangeable(attr) {
      return "changeable" in attr ? attr.changeable : true;
    }
  }
};
</script>