<template>
  <div class="requests-tab">
    <b-row class="py-4">
      <b-col lg="5" cols="9" class="my-2">
        <b-pagination class="my-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </b-col>

      <b-col lg="1" cols="3" class="pl-lg-0 my-auto my-1">
        <b-form-select size="sm" v-model="perPage" :options="perPageOptions"></b-form-select>
      </b-col>

      <b-col lg="6" md="12" sm="12" v-if="showButtons" class="my-lg-auto my-1">
        <div class="w-100 d-flex justify-content-end">
          <b-button
            size="sm"
            @click="toggleSelectAll"
            :disabled="filteredRequests.length===0"
          >{{ toggleBtnLabel }}</b-button>
          <b-button
            size="sm"
            variant="danger"
            v-b-modal="'rejectbulk'"
            class="ml-2"
            :disabled="filteredRequests.length===0"
          >
            <font-awesome-icon icon="times" class="mr-2"></font-awesome-icon>Reject selected
          </b-button>
          <b-button
            size="sm"
            variant="success"
            v-b-modal="'approvebulk'"
            class="ml-2"
            :disabled="filteredRequests.length===0"
          >
            <font-awesome-icon icon="check" class="mr-2"></font-awesome-icon>Approve selected
          </b-button>
          <confirm-modal
            :modalid="'approvebulk'"
            title="Bulk approval"
            @confirm="handleAcceptAll"
            bulk
          ></confirm-modal>
          <confirm-modal
            :modalid="'rejectbulk'"
            title="Bulk rejection"
            rejectModal
            @confirm="handleRejectAll"
            bulk
          ></confirm-modal>
        </div>
      </b-col>
      <b-col lg="6" class="my-lg-auto my-1">
        <filter-input v-model="filter" class="w-100" size="sm"></filter-input>
      </b-col>
    </b-row>

    <div v-if="loading" class="d-flex justify-content-center align-items-center my-4">
      <b-spinner variant="primary"></b-spinner>
    </div>

    <h5 class="text-danger" v-else-if="filter && filteredRequests.length===0">No results found</h5>

    <div v-else-if="filteredRequests.length">
      <transition-group name="list" tag="div">
        <identity-card
          v-for="req in visibleRequests"
          :key="`${req.request_type}-${req.data.id}-${req.data.created_at}`"
          :id-request="req"
          :editable="showButtons"
          :selected="isCardSelected(req)"
          @approve="handleApprove"
          @reject="handleReject"
          @approveCustom="approveCustom"
          @rejectCustom="rejectCustom"
          @approveExtCo="approveExtCo"
          @rejectExtCo="rejectExtCo"
        ></identity-card>
      </transition-group>

      <b-pagination class="mt-4" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
    </div>

    <h5 class="text-danger" v-else>You don't have any incoming requests</h5>
  </div>
</template>

<script>
import IdentityCard from "./IdentityCard";
import { mapState } from "vuex";
import ConfirmModal from "./ConfirmModal";
import FilterInput from "./Filter";
import { filterRequests } from "../_helpers/common";
export default {
  data() {
    return {
      loading: true,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 20, 50],
      filter: ""
    };
  },
  props: {
    tab: "pending" | "rejected" | "approved"
  },
  computed: {
    ...mapState("myrequests", [
      "incomingRequests",
      "selectedIncomingRequests",
      "selectedCustomRequests",
      "rejectComment"
    ]),
    rows() {
      return this.filteredRequests.length;
    },
    visibleRequests() {
      return this.filteredRequests.slice(
        (this.currentPage - 1) * this.perPage,
        (this.currentPage - 1) * this.perPage + this.perPage
      );
    },
    filteredRequests() {
      return filterRequests(this.filter, this.incomingRequests);
    },
    showButtons() {
      return this.tab === "pending";
    },
    toggleBtnLabel() {
      return this.selectedIncomingRequests.length || this.selectedCustomRequests.length
        ? "Unselect all"
        : "Select all";
    }
  },
  created() {
    console.log(this.tab + " created");
    if (this.$parent.active) {
      this.getRequests();
    }
  },

  methods: {
    getRequests() {
      axios.get(`/identity/requests/incoming/${this.tab}`).then(response => {
        this.loading = false;
        this.$store.commit("myrequests/ADD_INCOMING_REQUESTS", response.data.data);
          console.log(response.data.data);
      });
    },

    toggleSelectAll() {
      if (this.selectedCustomRequests.length || this.selectedIncomingRequests.length) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
    selectAll() {
      const allIdentityReq = this.incomingRequests.filter(
        r => r.request_type !== "custom_role_request"
      );
      const allCustomReq = this.incomingRequests.filter(
        r => r.request_type === "custom_role_request"
      );
      this.$store.commit("myrequests/ADD_REQUEST_TO_SELECTED", allIdentityReq);
      this.$store.commit("myrequests/ADD_CUSTOM_REQUEST_TO_SELECTED", allCustomReq);
    },
    deselectAll() {
      this.$store.commit("myrequests/CLEAR_SELECTED");
    },
    isCardSelected(req) {
      if (req.request_type === "custom_role_request") {
        return this.selectedCustomRequests.find(r => r.data.id === req.data.id) && true;
      } else {
        return this.selectedIncomingRequests.find(r => r.data.id === req.data.id) && true;
      }
    },

    handleAcceptAll() {
      if (this.selectedIncomingRequests.length) {
        this.handleAcceptAllIdentity();
      }
      if (this.selectedCustomRequests.length) {
        this.handleAcceptAllCustom();
      }
    },
    handleAcceptAllIdentity() {
      console.log("approve all identity requests");
      axios
        .post("/identity/accept", {
          requests: this.selectedIncomingRequests.map(r => ({
            id: r.data.id,
            master_id: r.data.master_id,
            version_no: r.data.version_no
          }))
        })
        .then(response => {
          this.$bvToast.toast(
            `Selected identity requests (${this.selectedIncomingRequests.length}) have been approved`,
            {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            }
          );
          this.$store.commit("myrequests/CLEAR_SELECTED_IDENTITY");
          this.getRequests();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },

    handleAcceptAllCustom() {
      console.log("approve all custom role requests");
      axios
        .post("/identity/custom_role_request/accept", {
          requests: this.selectedCustomRequests.map(r => ({
            id: r.data.requested_custom_role.custom_role_request_id
          }))
        })
        .then(response => {
          console.log(response.data);
          this.$bvToast.toast(
            `Selected custom role requests (${this.selectedCustomRequests.length}) have been approved`,
            {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            }
          );
          this.$store.commit("myrequests/CLEAR_SELECTED_CUSTOM");
          this.getRequests();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },

    handleRejectAll() {
      if (this.selectedIncomingRequests.length) {
        this.handleRejectAllIdentity();
      }
      if (this.selectedCustomRequests.length) {
        this.handleRejectAllCustom();
      }
    },

    handleRejectAllCustom() {
      console.log("handle reject all custom");
      axios
        .post("/identity/custom_role_request/reject", {
          requests: this.selectedCustomRequests.map(r => ({
            id: r.data.requested_custom_role.custom_role_request_id,
            comment: this.rejectComment
          }))
        })
        .then(response => {
          console.log(response.data);

          this.$bvToast.toast(
            `Selected custom role requests (${this.selectedCustomRequests.length}) have been rejected`,
            {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            }
          );
          this.$store.commit("myrequests/CLEAR_SELECTED_CUSTOM");
          this.getRequests();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },

    handleRejectAllIdentity() {
      console.log("handle reject all");
      axios
        .post("/identity/reject", {
          requests: this.selectedIncomingRequests.map(r => ({
            id: r.data.id,
            master_id: r.data.master_id,
            version_no: r.data.version_no,
            comment: this.rejectComment
          }))
        })
        .then(response => {
          console.log(response.data);
          this.$bvToast.toast(
            `Selected identity requests (${this.selectedIncomingRequests.length}) have been rejected`,
            {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            }
          );
          this.$store.commit("myrequests/CLEAR_SELECTED_IDENTITY");
          this.getRequests();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },

    handleApprove(req) {
      console.log("handle approve");
      axios
        .post("/identity/accept", {
          requests: [
            {
              master_id: req.data.master_id,
              version_no: req.data.version_no
            }
          ]
        })
        .then(response => {
          console.log(response.data);
          this.getRequests();
          this.$bvToast.toast("Request has been approved", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.$store.commit("myrequests/REMOVE_REQUEST_FROM_SELECTED", req);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    handleReject(req, comment) {
      console.log("handle reject, comment: " + comment);
      axios
        .post("/identity/reject", {
          requests: [
            {
              master_id: req.data.master_id,
              version_no: req.data.version_no,
              comment: comment
            }
          ]
        })
        .then(response => {
          console.log(response.data);
          this.getRequests();
          if (response.data.success) {
            this.$bvToast.toast("Request has been rejected", {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            });
            this.$store.commit("myrequests/REMOVE_REQUEST_FROM_SELECTED", req);
          } else {
            Object.keys(response.data.errors).forEach(key => {
              response.data.errors[key].forEach(err => {
                this.$bvToast.toast(err, {
                  title: "Error",
                  autoHideDelay: 6000,
                  variant: "danger",
                  solid: true
                });
              });
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    approveCustom(req) {
      console.log("handle approve custom role request");
      axios
        .post("/identity/custom_role_request/accept", {
          requests: [{ id: req.data.requested_custom_role.custom_role_request_id }]
        })
        .then(response => {
          console.log(response.data);
          this.getRequests();
          if (response.data.success) {
            this.$bvToast.toast("Custom role request has been accepted", {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            });
            this.$store.commit("myrequests/REMOVE_CUSTOM_REQUEST_FROM_SELECTED", req);
          } else {
            Object.keys(response.data.errors).forEach(key => {
              response.data.errors[key].forEach(err => {
                this.$bvToast.toast(err, {
                  title: "Error",
                  autoHideDelay: 6000,
                  variant: "danger",
                  solid: true
                });
              });
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    rejectCustom(req, comment) {
      console.log("handle approve custom role request");
      axios
        .post("/identity/custom_role_request/reject", {
          requests: [
            {
              id: req.data.requested_custom_role.custom_role_request_id,
              comment: comment
            }
          ]
        })
        .then(response => {
          console.log(response.data);
          this.getRequests();
          if (response.data.success) {
            this.$bvToast.toast("Custom role request has been rejected", {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            });
            this.$store.commit("myrequests/REMOVE_CUSTOM_REQUEST_FROM_SELECTED", req);
          } else {
            Object.keys(response.data.errors).forEach(key => {
              response.data.errors[key].forEach(err => {
                this.$bvToast.toast(err, {
                  title: "Error",
                  autoHideDelay: 6000,
                  variant: "danger",
                  solid: true
                });
              });
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    
    approveExtCo(req) {
        console.log("handle approve extco role request");
        axios
            .post("/external_company/accept", {
                requests: [{
                    master_id: req.data.master_id,
                    version_no: req.data.version_no
                }]
            })
            .then(response => {
                console.log(response.data);
                this.getRequests();
                if (response.data.success) {
                    this.$bvToast.toast("External company request has been accepted", {
                        title: "Success",
                        autoHideDelay: 5000,
                        variant: "success",
                        solid: true
                    });
                    this.$store.commit("myrequests/REMOVE_CUSTOM_REQUEST_FROM_SELECTED", req);
                } else {
                    Object.keys(response.data.errors).forEach(key => {
                        response.data.errors[key].forEach(err => {
                            this.$bvToast.toast(err, {
                                title: "Error",
                                autoHideDelay: 6000,
                                variant: "danger",
                                solid: true
                            });
                        });
                    });
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    },

    rejectExtCo(req, comment) {
        console.log("handle approve extco request");
        axios
            .post("/external_company/reject", {
                requests: [{
                    master_id: req.data.master_id,
                    version_no: req.data.version_no,
                    comment: comment
                }]
            })
            .then(response => {
                console.log(response.data);
                this.getRequests();
                if (response.data.success) {
                    this.$bvToast.toast("External company request has been rejected", {
                        title: "Success",
                        autoHideDelay: 5000,
                        variant: "success",
                        solid: true
                    });
                    this.$store.commit("myrequests/REMOVE_CUSTOM_REQUEST_FROM_SELECTED", req);
                } else {
                    Object.keys(response.data.errors).forEach(key => {
                        response.data.errors[key].forEach(err => {
                            this.$bvToast.toast(err, {
                                title: "Error",
                                autoHideDelay: 6000,
                                variant: "danger",
                                solid: true
                            });
                        });
                    });
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }
  },
  components: {
    IdentityCard,
    ConfirmModal,
    FilterInput
  }
};
</script>
