<template>
  <b-container>
    <h2 class="mb-4">Incoming requests</h2>
    <p>{{ title }}</p>

    <b-tabs lazy v-model="tabIndex">
      <b-tab title="Pending" @click="tabIndex=0" :active="tabIndex===0">
        <incoming-request-tab tab="pending"></incoming-request-tab>
      </b-tab>
      <b-tab title="Approved" @click="tabIndex=1" :active="tabIndex===1">
        <incoming-request-tab tab="approved"></incoming-request-tab>
      </b-tab>
      <b-tab title="Rejected" @click="tabIndex=2" :active="tabIndex===2">
        <incoming-request-tab tab="rejected"></incoming-request-tab>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import IncomingRequestTab from "../components/IncomingRequestTab";

export default {
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    title() {
      switch (this.tabIndex) {
        case 0:
          return "The list below shows incoming requests that you are eligible to approve or reject.";
        case 1:
          return "The list below shows incoming requests that were approved by you.";
        case 2:
          return "The list below shows incoming requests that were rejected by you.";
      }
    }
  },
  components: {
    IncomingRequestTab
  }
};
</script>