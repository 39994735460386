<template>
  <b-container>
    <h2 class="mb-4">Reports</h2>

    <b-row align-h="between">
      <div class="d-flex align-items-center">
        <export-button
          btnVariant="primary"
          btnClass="mr-2"
          :data="identitiesData"
          type="xlsx"
          label="Export (xlsx)"
        ></export-button>
        <div v-if="authorizedToExportTim">
          <export-button
            btnClass="mr-2"
            btnVariant="outline-primary"
            type="csv"
            label="Export all (csv)"
          ></export-button>
          <span>CSV export always contains all data!</span>
        </div>
      </div>

      <b-col md="2">
        <b-form-select v-model="selectedStatus" :options="statusOptions" @change="getAllData"></b-form-select>
      </b-col>
    </b-row>

    <div v-if="loading" class="text-center mt-5">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>

    <div v-else>
      <b-row class="mt-5">
        <b-col sm="12" md="4">
          <h5>Number of identities</h5>
          <ul>
            <li v-if="selectedStatus==='all'">All: {{ allCnt }}</li>
            <li v-if="['all', 'active'].includes(selectedStatus)">Active: {{ activeCnt }}</li>
            <li v-if="['all', 'inactive'].includes(selectedStatus)">Inactive: {{ inactiveCnt }}</li>
          </ul>
        </b-col>

        <b-col sm="12" md="4">
          <h5>Companies</h5>
          <ul>
            <li v-for="comp in companies" :key="comp.id">{{comp.name}}: {{ comp.count }}</li>
          </ul>
        </b-col>

        <b-col sm="12" md="4">
          <h5>Employment types</h5>
          <ul>
            <li v-for="et in employmentTypes" :key="et.id">{{et.type}}: {{ et.count }}</li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col sm="12" md="4">
          <h5>External companies</h5>
          <ul>
            <li v-for="comp in externalCompanies" :key="comp.id">{{comp.name}}: {{ comp.count }}</li>
          </ul>
        </b-col>

        <b-col sm="12" md="4">
          <h5>Organization units</h5>
          <ul>
            <li v-for="o in orgUnits" :key="o.id">{{o.name}}: {{ o.count }}</li>
          </ul>
        </b-col>

        <b-col sm="12" md="4">
          <h5>Departments</h5>
          <ul>
            <li v-for="d in departments" :key="d.id">{{d.name}}: {{ d.count }}</li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col sm="12" md="4">
          <h5>Primary roles</h5>
          <ul>
            <li v-for="role in primaryRoles" :key="role.id">{{role.name}}: {{ role.count }}</li>
          </ul>
        </b-col>

        <b-col sm="12" md="4">
          <h5>Cost centers</h5>
          <ul>
            <li v-for="cc in costCenters" :key="cc.id">{{cc.name}}: {{ cc.count }}</li>
          </ul>
        </b-col>
      </b-row>
      <TreeChart :json="treeData" />
    </div>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import fileDownload from "js-file-download";
import ExportButton from "../components/ExportButton";
import { authenticationService } from "../_services";
import TreeChart from "vue-tree-chart";

export default {
  data() {
    return {
      identitiesData: [],
      selectedStatus: "all",
      loading: true,
      statusOptions: [
        { value: "all", text: "All" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" }
      ],
    };
  },
  computed: {
    allCnt() {
      return this.identitiesData.length || "";
    },
    authorizedToExportTim() {
      return authenticationService.isAdmin() || authenticationService.isHR();
    },
    activeCnt() {
      return this.identitiesData.filter(d => d.user_status === "Active").length || "";
    },
    inactiveCnt() {
      return this.identitiesData.filter(d => d.user_status === "Inactive").length || "";
    },
    companies() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.company_name;
        if (!accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        accumulator[name]++;
        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    employmentTypes() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var type = data.employment_type;
        if (!accumulator.hasOwnProperty(type)) {
          accumulator[type] = 0;
        }
        accumulator[type]++;
        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ type: k, count: counts[k] }))
        .sort((a, b) => a.type > b.type);
    },
    orgUnits() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.organizational_unit_name;
        if (!accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        accumulator[name]++;
        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    departments() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.department_name;
        if (!accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        accumulator[name]++;
        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    primaryRoles() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.primary_role_name;
        if (!accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        accumulator[name]++;
        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    externalCompanies() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.external_company_name;
        if (name !== null && name !== "" && !accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        if (name) {
          accumulator[name]++;
        }

        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    costCenters() {
      var counts = this.identitiesData.reduce((accumulator, data) => {
        var name = data.cost_center_code;
        if (name !== null && name !== "" && !accumulator.hasOwnProperty(name)) {
          accumulator[name] = 0;
        }
        if (name) {
          accumulator[name]++;
        }

        return accumulator;
      }, {});
      return Object.keys(counts)
        .map(k => ({ name: k, count: counts[k] }))
        .sort((a, b) => a.name > b.name);
    },
    treeData(){
        const fn=(data,root_id,used_root_ids=[])=>{
            const root=this.identitiesData.find((data)=>data.master_id===root_id);
            if(!root)
                return;

            return {
                name:root.full_name,
                image_url:"/img/profile.png",
                children:this.identitiesData.filter((data)=>data.direct_manager_id===root.id)
                    .filter(d=>!used_root_ids.includes(d.id))
                    .map((child)=>fn(data,child.master_id,[...used_root_ids, root.id]))
            }
        };
        return  fn(this.identitiesData,authenticationService.getTokenData().id);
    }
  },
  created() {
    this.getAllData();
  },
  methods: {
    getAllData() {
      this.loading = true;
      axios
        .get(`/identity/live/${this.selectedStatus}`)
        .then(response => {
          this.identitiesData = response.data.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    }
  },
  components: {
    ExportButton,
    TreeChart
  }
};
</script>