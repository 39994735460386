<template>
  <div>
    <b-container>
      <h2 class="mb-4">Profile</h2>

      <p>Below is the latest active revision of your profile.</p>

      <b-row class="my-4">
        <b-col md="10">
          <user-details-row attrName="Privileges" :attrValue="myRoles"></user-details-row>
        </b-col>
      </b-row>

      <user-details v-if="user" :details="userData"></user-details>
    </b-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatDate, prettyConcat } from "../_helpers";
import UserDetails from "../components/UserDetails";
import UserDetailsRow from "../components/UserDetailsRow";
import { authenticationService } from "../_services";
export default {
  computed: {
    ...mapState("authentication", ["user"]),
    ...mapState("settings", ["dateFormat"]),
    userData() {
      return { data: this.user };
    },
    myRoles() {
      return prettyConcat(authenticationService.getRoles());
    }
  },
  methods: {
    formatDate(dateStr) {
      return formatDate(dateStr, this.dateFormat);
    }
  },
  components: {
    UserDetails,
    UserDetailsRow
  }
};
</script>