<template>
  <div>
    <b-row>
      <b-col md="3">
        <strong>{{ attrName }}</strong>:
      </b-col>

      <b-col md="9">
        <template v-if="isAttrChangeable(attrValue)">
          <!-- if attribute changed show old vs new values -->
          <template v-if="attrValue.changed">
            <!-- not a list just a regular attribute -->
            <user-details-row-highlight
              :oldVal="attrValue.old"
              :newVal="attrValue.new"
              :date="date"
              :datetime="datetime"
            ></user-details-row-highlight>
          </template>

          <!-- attribute not changed -->
          <template v-else>
            <user-details-value :value="attrValue.value" :date="date" :datetime="datetime"></user-details-value>
          </template>
        </template>

        <!-- attribute is a simple value, not changeable -->
        <template v-else>
          <user-details-value :value="attrValue" :date="date" :datetime="datetime"></user-details-value>
        </template>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { isObject } from "../_helpers";
import UserDetailsRowHighlight from "./UserDetailsRowHighlight";

import UserDetailsValue from "./UserDetailsValue";
export default {
  props: {
    attrName: String,
    attrValue: Object | String,
    date: Boolean,
    datetime: Boolean
  },
  methods: {
    isAttrChangeable(attrVal) {
      return isObject(attrVal) && attrVal.hasOwnProperty("changed");
    }
  },
  components: {
    UserDetailsRowHighlight,
    UserDetailsValue
  }
};
</script>