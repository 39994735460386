<template>
  <div>
    <navbar v-if="['login', 'register'].indexOf($route.name) < 0"></navbar>
    <bootstrap-size></bootstrap-size>
    <b-container fluid>
      <div class="my-5">
        <router-view></router-view>
      </div>
    </b-container>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import BootstrapSize from "../components/BootstrapSize";
export default {
  components: {
    Navbar,
    BootstrapSize
  }
};
</script>