import { format } from "date-fns";
import { isSameDay } from "date-fns/esm";

export const formatDate = (dateStr, selectedFormat) => {
  return dateStr && selectedFormat ? format(new Date(dateStr), selectedFormat) : "";
};

export const capitalize = str => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const deCapitalize = str => {
  return str && str.charAt(0).toLowerCase() + str.slice(1);
};

export function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}

// returns the intersection of two arrays
export function intersect(a, b) {
  var setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
}

// returns the written weekday of a Date
export function getWeekDayName(date) {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  return weekdays[date.getDay()];
}

// returns true if two dates are on the same calendar day
// export function isSameDate(date1, date2) {
//   return (
//     date1.getDay() === date2.getDay() &&
//     date1.getMonth() === date2.getMonth() &&
//     date1.getFullYear() === date2.getFullYear()
//   );
// }

export const filterRequests = (filter, requests) => {
  if (filter === "") {
    return requests;
  }
  return requests.filter(req => {
    // we create a long string by joining all the searchable field's value in the request
    const filterableFields = [
      req.data.username,
      req.data.full_name,
      req.data.last_modifier_full_name,
      req.data.department_name,
      req.data.organizational_unit_name
    ]
      .map(
        field =>
          typeof field === "string" // regular string
            ? field.toLowerCase()
            : typeof field === "object" // changed: true etc object
            ? field.changed
              ? `${field.old} ${field.new}`.toLowerCase()
              : field.value
            : field // number
      )
      .join(" ");

    // if we find a word in the query that is not present in the above
    // string  then do NOT return that row as a match
    return !filter
      .split(" ")
      .find(word => !filterableFields.includes(word.toLowerCase()));
  });
};

// concatenates the values of the array with a comma but uses 'and' in place of the last comma
export const prettyConcat = function(array) {
  return array
    .sort()
    .join(", ")
    .replace(/,(?!.*,)/gim, " and");
};
