<template>
  <b-row class="justify-content-center">
    <b-col xl="8" lg="10" md="10" sm="12">
      <b-card :title="staticData.cardTitle">
        <b-row class="mt-5">
          <b-col md="4">
            <img class="profilepic" src="/img/profile.png" />
          </b-col>
          <b-col md="8">
            <b-row>
              <b-col md="4">
                <strong>Prefix:</strong>
              </b-col>
              <b-col md="8">{{ item.title || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>First name:</strong>
              </b-col>
              <b-col md="8">{{ item.first_name || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Last name:</strong>
              </b-col>
              <b-col md="8">{{ item.last_name || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Email:</strong>
              </b-col>
              <b-col md="8">{{ item.email || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Start date:</strong>
              </b-col>
              <b-col md="8">{{ formatDate(item.start_date) || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>End date:</strong>
              </b-col>
              <b-col md="8">{{ formatDate(item.end_date) || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Last workday:</strong>
              </b-col>
              <b-col md="8">{{ formatDate(item.last_workday) || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Tax ID:</strong>
              </b-col>
              <b-col md="8">{{ item.tax_id || "-" }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <strong>Status:</strong>
              </b-col>
              <b-col md="8">{{ userStatus || "-" }}</b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-card title="Company information" class="mt-4">
          <b-row>
            <b-col md="4">
              <strong>Employment type:</strong>
            </b-col>
            <b-col md="8">{{ employmentType }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Company:</strong>
            </b-col>
            <b-col md="8">{{ companyName }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Location/Office:</strong>
            </b-col>
            <b-col md="8">{{ locationName }}</b-col>
          </b-row>

          <b-row v-if="employmentType !== 'Employee'">
            <b-col md="4">
              <strong>External company:</strong>
            </b-col>
            <b-col md="8">{{ externalCompanyName || "-" }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Primary role:</strong>
            </b-col>
            <b-col md="8">{{ primaryRole || "-" }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Access level:</strong>
            </b-col>
            <b-col md="8">{{ accessLevel }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Custom roles:</strong>
            </b-col>
            <b-col md="8">
              <ul v-if="customRoles.length" :style="{ paddingLeft: '15px' }">
                <li v-for="role in customRoles" :key="role.id">{{ role.name }}</li>
              </ul>
              <span v-else>-</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Department:</strong>
            </b-col>
            <b-col md="8">{{ departmentName }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Organizational unit:</strong>
            </b-col>
            <b-col md="8">{{ organizationalUnitName }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Direct manager:</strong>
            </b-col>
            <b-col md="8">{{ directManagerFullName }}</b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <strong>Unique rights:</strong>
            </b-col>
            <b-col md="8">{{ item.unique_rights_request || "-" }}</b-col>
          </b-row>

          <b-col class="d-flex mt-3 justify-content-end">
            <b-button variant="secondary" class="mr-4" @click="prev">
              <font-awesome-icon icon="chevron-left" class="mr-2" />Edit
            </b-button>
            <b-button variant="primary" v-b-modal="'identity-confirm-modal'">
              <font-awesome-icon icon="paper-plane" class="mr-2" />Send
            </b-button>
          </b-col>
        </b-card>
      </b-card>

      <b-modal id="identity-confirm-modal" title="Confirmation" @ok="send">
        <p class="my-4">{{ staticData.confirmModalText }}</p>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { formatDate } from "../_helpers";
import { previewAttributes } from "../_helpers/models";
export default {
  props: {
    requestType: String
  },
  computed: {
    ...mapState("requestform", ["item"]),
    ...mapGetters("requestform", [
      "companyOptions",
      "locationOptions",
      "externalCompanyOptions",
      "employmentTypeOptions",
      "accessLevelOptions",
      "primaryRoleOptions",
      "statusOptions"
    ]),
    ...mapState("settings", ["dateFormat"]),
    postData() {
      return {
        ...this.item,
        custom_roles: this.customRoles.map(r => r.id)
      };
    },
    previewAttrs() {
      return previewAttributes;
    },
    isModifyRequest() {
      return this.$route.name === "editIdentity";
    },
    isNewRequest() {
      return this.$route.name === "newIdentity";
    },
    userStatus() {
      if (this.isModifyRequest) {
        const found = this.statusOptions.find(
          opt => opt.value === this.item.user_status_id
        );
        return found ? found.text : "-";
      }
      // new request
      return "Active";
    },
    companyName() {
      const found = this.companyOptions.find(opt => opt.value === this.item.company_id);
      return found ? found.text : "-";
    },
    locationName() {
      const found = this.locationOptions.find(opt => opt.value === this.item.location_id);
      return found ? found.text : "-";
    },
    externalCompanyName() {
      const found = this.externalCompanyOptions.find(
        opt => opt.value === this.item.external_company_id
      );
      console.log("found ext.comp: " + JSON.stringify(found));
      return found ? found.text : "-";
    },
    employmentType() {
      const found = this.employmentTypeOptions.find(
        opt => opt.value === this.item.employment_type_id
      );
      return found ? found.text : "-";
    },
    primaryRole() {
      const found = this.primaryRoleOptions.find(
        opt => opt.value === this.item.primary_role_id
      );
      return found ? found.role_name : "-";
    },
    accessLevel() {
      const found = this.accessLevelOptions.find(
        opt => opt.value === this.item.primary_role_access_level_id
      );
      return found ? found.text : "-";
    },
    customRoles() {
      return !this.item.custom_roles
        ? []
        : this.item.custom_roles.filter(role => role.approval_status !== "Rejected");
    },
    fullName() {
      const fn = this.item.full_name;
      if (fn) return fn;
      if (this.item.title)
        return `${this.item.title} ${this.item.first_name} ${this.item.last_name}`;
      return `${this.item.first_name} ${this.item.last_name}`;
    },
    departmentName() {
      const found = this.primaryRoleOptions.find(
        opt => opt.value === this.item.primary_role_id
      );
      return found ? `${found.department_name} (${found.department_code})` : "";
    },
    directManagerFullName() {
      const found = this.primaryRoleOptions.find(
        opt => opt.value === this.item.primary_role_id
      );
      return found ? `${found.manager_name} (${found.manager_username})` : "";
    },
    organizationalUnitName() {
      const found = this.primaryRoleOptions.find(
        opt => opt.value === this.item.primary_role_id
      );
      return found ? `${found.org_unit_name} (${found.org_unit_code})` : "";
    },
    staticData() {
      return this.isModifyRequest
        ? {
            url: `/identity/modify/${this.item.master_id}/${this.item.version_no}`,
            success: "Modification request has been sent",
            cardTitle: "Preview for identity modification",
            confirmModalText: `Modify identity for user ${this.item.full_name} (${this.item.username})?`
          }
        : {
            // new request
            url: "/identity/create",
            success: "New identity request has been sent",
            cardTitle: "Preview for new identity request",
            confirmModalText: `Create new identity for ${this.fullName}?`
          };
    }
  },
  methods: {
    formatDate(dateStr) {
      return formatDate(dateStr, this.dateFormat);
    },
    prev() {
      this.$store.dispatch("requestform/stepPrev");
    },
    send() {
      console.log("sending request...");

      axios
        .post(this.staticData.url, this.postData)
        .then(response => {
          if (response.data.success) {
            // show success toast on parent component because the preview will be destroyed
            this.$emit("success", this.staticData.success);
            // reset form progress
            this.$store.dispatch("requestform/resetFormProgress");
          } else {
            console.log(response.data);
          }
        })
        .catch(error => {
          console.log(error.response.data);
          const errors = error.response.data.errors;
          // show error toast for each error message
          Object.keys(errors).forEach(key => {
            errors[key].forEach(err => {
              this.$bvToast.toast(err, {
                title: "Error",
                autoHideDelay: 6000,
                variant: "danger",
                solid: true
              });
              if (err.includes("email has already been taken")) {
                this.$store.commit("requestform/SET_EMAIL_TAKEN", true);
              }
            });
          });
        });
    }
  }
};
</script>

<style scoped lang="css">
.profilepic {
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-width: 200px;
}
.card-title {
  color: #6c757d !important;
}
</style>
