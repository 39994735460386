<template>
  <b-container>
    <h2>Custom role editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group description label="Custom role name*">
            <b-form-input
              id="custom_name"
              v-model="custom_role_name"
              :state="$v.custom_role_name.$dirty ? !$v.custom_role_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="custom_name">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group description label="Data owner*">
            <b-form-select
              id="custom_owner"
              v-model="custom_role_data_owner"
              :options="dataOwnerOptions"
              :state="$v.custom_role_data_owner.$dirty ? !$v.custom_role_data_owner.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="custom_owner">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group description label="TIM Templates*">
            <multiselect
              v-model="custom_tim_templates"
              :multiple="true"
              track-by="id"
              label="name"
              :options="timOptions"
            ></multiselect>
          </b-form-group>
  
          <b-form-group description label="Non-AD Templates*">
            <multiselect
              v-model="custom_non_ad_templates"
              :multiple="true"
              track-by="id"
              label="name"
              :options="nonADOptions"
            ></multiselect>
          </b-form-group>
          
          <b-form-checkbox v-model="hidden" name="hidden">Hidden</b-form-checkbox>

          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing custom roles</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(tim_templates)" slot-scope="row">
        <b-badge
          v-for="temp in row.value"
          :key="temp.name"
          variant="info"
          class="m-1 p-1"
        >{{ `${temp.name} (${temp.profile})` }}</b-badge>
      </template>
  
      <template slot="cell(non_ad_templates)" slot-scope="row">
        <b-badge
          v-for="temp in row.value"
          :key="temp.name"
          variant="info"
          class="m-1 p-1"
        >{{ `${temp.name} (${temp.profile})` }}</b-badge>
      </template>
      
      <template slot="cell(hidden)" slot-scope="row">
        <span v-if="row.item.hidden" class="text-danger">No</span>
        <span v-else class="text-success">Yes</span>
      </template>

      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import { FormSelect } from "../../_helpers/models";
import WarningText from "../WarningText";
export default {
  created() {
    this.getCustomRoles();
    this.getDataOwners();
    this.getTimTemplates();
    this.getNonADTemplates();
  },
  data() {
    return {
      show: true,

      custom_role_name: "",
      custom_tim_templates: [],
      custom_non_ad_templates: [],
      custom_role_data_owner: "",
      hidden: false,

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "data_owner",
          sortable: true
        },
        {
          key: "tim_templates",
          label: "TIM templates name (profile)"
        },
        {
          key: "non_ad_templates",
          label: "Non-AD templates name (profile)"
        },
        {
          key: "hidden",
          sortable: true,
          label: "Visible"
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listTimTemplates: [],
      listNonADTemplates: [],
      listCustomRoles: [],
      listDataOwners: [],

      loading: true,
      defaultOption: { value: "", text: "Select option" },
      filter: "",
      editId: null
    };
  },
  validations: {
    custom_role_name: {
      required
    },
    custom_role_data_owner: {
      required
    }
  },
  computed: {
    postData() {
      return {
        name: this.custom_role_name,
        tim_templates: this.custom_tim_templates.concat(this.custom_non_ad_templates).map(opt => opt.id),
        data_owner_id: this.custom_role_data_owner,
        hidden: this.hidden
      };
    },
    postUrl() {
      return this.editId ? `/custom_role/edit/${this.editId}` : "/custom_role/create";
    },
    successMsg() {
      return this.editId ? "Custom role modification OK" : "New custom role added";
    },
    formTitle() {
      return this.editId ? "Edit custom role" : "New custom role";
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },

    timOptions() {
      return this.listTimTemplates.map(t => ({ name: t.name, id: t.id }));
    },
    
    nonADOptions() {
      return this.listNonADTemplates.map(t => ({ name: t.name, id: t.id }));
    },

    dataOwnerOptions() {
      const options = this.listDataOwners.map(owner => ({
        value: owner.id,
        text: `${owner.full_name} (${owner.username})`
      }));
      return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getCustomRoles();
        })
        .catch(error => {
          console.log(error.response);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.custom_role_name = "";
      this.custom_tim_templates = [];
      this.custom_non_ad_templates = [];
      this.custom_role_data_owner = "";
      this.hidden = false;

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getDataOwners() {
      axios
        .get("/custom_role/potential_data_owners")
        .then(response => (this.listDataOwners = response.data.data))
        .catch(error => console.log("Failed to get data owners: " + error));
    },

    getTimTemplates() {
      axios.get("/tim_template/list/tim").then(response => {
        this.listTimTemplates = response.data.data;
      });
    },

    getNonADTemplates() {
        axios.get("/tim_template/list/non_ad").then(response => {
            this.listNonADTemplates = response.data.data;
        });
    },
    
    getCustomRoles() {
      this.loading = true;

      axios.get("/custom_role/list").then(response => {
          this.listCustomRoles = response.data.data.map(role => ({
          ...role,
          data_owner: `${role.data_owner_full_name} (${role.data_owner_username})`,
          tim_templates: role.tim_templates.map(t => ({
            id: t.id,
            name: t.name,
            profile: t.profile
          })),
          non_ad_templates: role.non_ad_templates.map(t => ({
              id: t.id,
              name: t.name,
              profile: t.profile
          }))
        }));

        this.tableData = this.listCustomRoles;
        this.loading = false;
      });
    },

    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);

      this.custom_role_name = row.name;
      this.custom_tim_templates = row.tim_templates.map(t => ({
        id: t.id,
        name: t.name
      }));
      this.custom_non_ad_templates = row.non_ad_templates.map(t => ({
          id: t.id,
          name: t.name
      }));
      this.custom_role_data_owner = row.data_owner_id;
      this.hidden = row.hidden;
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>
