export const myrequests = {
  namespaced: true,
  state: {
    // requests on the incoming requests page
    incomingRequests: [],

    // checked identity requests on the incoming requests page
    selectedIncomingRequests: [],

    // checked custom role requests on the incoming requests page
    selectedCustomRequests: [],

    // comment to send on rejection of request
    rejectComment: ""
  },
  mutations: {
    ADD_REQUEST_TO_SELECTED(state, req) {
      if (Array.isArray(req)) {
        state.selectedIncomingRequests.push(...req);
      } else {
        state.selectedIncomingRequests.push(req);
      }
    },
    ADD_CUSTOM_REQUEST_TO_SELECTED(state, req) {
      if (Array.isArray(req)) {
        state.selectedCustomRequests.push(...req);
      } else {
        state.selectedCustomRequests.push(req);
      }
    },
    REMOVE_REQUEST_FROM_SELECTED(state, req) {
      if (Array.isArray(req)) {
        req.forEach(r => {
          const idx = state.selectedIncomingRequests
            .map(r => r.data.id)
            .indexOf(req.data.id);
          state.selectedIncomingRequests.splice(idx, 1);
        });
      } else {
        state.selectedIncomingRequests.splice(
          state.selectedIncomingRequests.map(r => r.data.id).indexOf(req.data.id),
          1
        );
      }
    },
    REMOVE_CUSTOM_REQUEST_FROM_SELECTED(state, req) {
      if (Array.isArray(req)) {
        req.forEach(r => {
          const idx = state.selectedCustomRequests
            .map(r => r.data.id)
            .indexOf(req.data.id);
          state.selectedCustomRequests.splice(idx, 1);
        });
      } else {
        state.selectedCustomRequests.splice(
          state.selectedCustomRequests.map(r => r.data.id).indexOf(req.data.id),
          1
        );
      }
    },

    CLEAR_SELECTED(state) {
      state.selectedIncomingRequests = [];
      state.selectedCustomRequests = [];
    },
    CLEAR_SELECTED_IDENTITY(state) {
      state.selectedIncomingRequests = [];
    },
    CLEAR_SELECTED_CUSTOM(state) {
      state.selectedCustomRequests = [];
    },

    ADD_INCOMING_REQUESTS(state, requests) {
      state.incomingRequests = requests;
    },
    REMOVE_INCOMING_REQUEST(state, reqs) {
      state.incomingRequests = state.incomingRequests.filter(
        r => !reqs.map(req => req.id).includes(r.data.id)
      );
    },
    SET_REJECT_COMMENT(state, comment) {
      state.rejectComment = comment;
    }
  },
  actions: {},
  getters: {
    getRejectComment: state => {
      return state.rejectComment;
    }
  }
};
