<template>
  <b-container>
    <h2>EA entity editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group label="Company name*">
            <b-form-input
              id="comp_name"
              v-model="company_name"
              :state="$v.company_name.$dirty ? !$v.company_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="comp_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Company code*">
            <b-form-input
              id="comp_code"
              v-model="company_code"
              :state="$v.company_code.$dirty ? !$v.company_code.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="comp_code">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing EA entities</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import { FormSelect } from "../../_helpers/models";
import WarningText from "../WarningText";
export default {
  created() {
    this.getCompanies();
  },
  data() {
    return {
      show: true,

      company_name: "",
      company_code: "",

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "code",
          sortable: true
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listTimTemplates: [],
      listCustomRoles: [],
      listDataOwners: [],

      loading: true,
      defaultOption: { value: "", text: "Select option" },
      filter: "",
      editId: null
    };
  },
  validations: {
    company_name: {
      required
    },
    company_code: {
      required
    }
  },
  computed: {
    postData() {
      return {
        name: this.company_name,
        code: this.company_code
      };
    },
    postUrl() {
      return this.editId ? `/company/edit/${this.editId}` : "/company/create";
    },
    successMsg() {
      return this.editId ? "EA entity modification OK" : "New EA entity added";
    },
    formTitle() {
      return this.editId ? "Edit EA entity" : "New EA entity";
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },

    timOptions() {
      return this.listTimTemplates.map(t => ({ name: t.name, id: t.id }));
    },

    dataOwnerOptions() {
      const options = this.listDataOwners.map(owner => ({
        value: owner.id,
        text: `${owner.full_name} (${owner.username})`
      }));
      return [this.defaultOption, ...options];
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getCompanies();
        })
        .catch(error => {
          console.log(error.response);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.company_name = "";
      this.company_code = "";

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getCompanies() {
      this.loading = true;

      axios
        .get("/company/list")
        .then(response => {
          this.listCompanies = response.data.data;

          this.loading = false;
          this.tableData = this.listCompanies;
        })
        .catch(error => console.log("Failed to get companies: " + error));
    },

    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);

      this.company_name = row.name;
      this.company_code = row.code;
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>