<template>
  <div v-if="loaded">
    <div v-if="showHistory" class="d-flex align-items-center mb-2">
      <span class="mr-2">History:</span>
      <b-button variant="primary" size="sm" @click="stepPrevHistory" :disabled="prevDisabled">
        <font-awesome-icon icon="arrow-left" class="mr-2"></font-awesome-icon>Older
      </b-button>
      <b-button
        variant="primary"
        class="ml-2"
        size="sm"
        @click="stepNextHistory"
        :disabled="nextDisabled"
      >
        Newer
        <font-awesome-icon icon="arrow-right" class="ml-1"></font-awesome-icon>
      </b-button>
      <b-button
        variant="outline-primary"
        size="sm"
        title="View the most recent accepted revision of the user"
        class="ml-4"
        @click="stepToLatest"
      >Latest</b-button>

      <div class="ml-5">
        <b-button
          v-if="showInactivateBtn"
          variant="outline-danger"
          size="sm"
          title="Set this user to inactive status"
          class="ml-4"
          @click="deactivateClicked = true; activateClicked = false; putClickedOnLeave = false"
          v-b-modal="`status-change-${this.latestUserData.id}`"
        >
          <font-awesome-icon icon="ban" class="mr-2" />Deactivate
        </b-button>
        <b-button
          v-if="showActivateBtn"
          variant="outline-success"
          size="sm"
          title="Set this user to active status"
          class="ml-4"
          @click="activateClicked = true; deactivateClicked = false; putClickedOnLeave = false"
          v-b-modal="`status-change-${this.latestUserData.id}`"
        >
          <font-awesome-icon icon="redo" class="mr-2" />Activate
        </b-button>
        <b-button
          v-if="showPutOnLeaveBtn"
          variant="outline-dark"
          size="sm"
          title="Set this user to active status"
          class="ml-4"
          @click="putClickedOnLeave = true; activateClicked = false; deactivateClicked = false"
          v-b-modal="`status-change-${this.latestUserData.id}`"
        >
          <font-awesome-icon icon="ban" class="mr-2" />Put on leave
        </b-button>

        <b-modal
          :id="`status-change-${this.latestUserData.id}`"
          title="Confirmation"
          @ok="send"
        >{{ staticData.modalText }}</b-modal>
      </div>
    </div>

    <b-row>
      <b-col md="10" class="order-2 order-sm-1">
        <h4 v-if="showTitle">{{ detailsTitle }}</h4>
        <user-details-row attrName="Version No" :attrValue="history.data.version_no"></user-details-row>
        <user-details-row attrName="Modified at" :attrValue="history.data.created_at" datetime></user-details-row>
        <user-details-row attrName="Modified by" :attrValue="history.data.last_modifier_full_name"></user-details-row>
        <user-details-row attrName="Reviewed at" :attrValue="history.data.reviewed_at" datetime></user-details-row>
        <user-details-row attrName="Reviewed by" :attrValue="history.data.reviewer_full_name"></user-details-row>
        <user-details-row attrName="Username" :attrValue="history.data.username"></user-details-row>
        <user-details-row attrName="Email" :attrValue="history.data.email"></user-details-row>
        <user-details-row attrName="Full name" :attrValue="history.data.full_name"></user-details-row>
        <user-details-row attrName="Prefix" :attrValue="history.data.title"></user-details-row>
        <user-details-row attrName="First name" :attrValue="history.data.first_name"></user-details-row>
        <user-details-row attrName="Last name" :attrValue="history.data.last_name"></user-details-row>
        <user-details-row attrName="Tax ID" :attrValue="history.data.tax_id"></user-details-row>
        <user-details-row attrName="Employment type" :attrValue="history.data.employment_type"></user-details-row>
        <user-details-row attrName="Company" :attrValue="history.data.company_name"></user-details-row>
        <user-details-row attrName="Location/Office" :attrValue="history.data.location_name"></user-details-row>
        <user-details-row
          attrName="External company"
          :attrValue="history.data.external_company_name"
        ></user-details-row>
        <user-details-row attrName="Start date" :attrValue="history.data.start_date" date></user-details-row>
        <user-details-row attrName="End date" :attrValue="history.data.end_date" date></user-details-row>
        <user-details-row attrName="Last workday" :attrValue="history.data.last_workday" date></user-details-row>
        <user-details-row attrName="Status" :attrValue="history.data.user_status"></user-details-row>

        <user-details-row
          attrName="Direct manager"
          :attrValue="history.data.direct_manager_full_name"
        ></user-details-row>
        <user-details-row attrName="Department" :attrValue="history.data.department_name"></user-details-row>
        <user-details-row
          attrName="Organizational unit"
          :attrValue="history.data.organizational_unit_name"
        ></user-details-row>
        <user-details-row attrName="Cost center" :attrValue="history.data.cost_center_code"></user-details-row>

        <user-details-row attrName="Primary role" :attrValue="history.data.primary_role_name"></user-details-row>

        <user-details-row-access
          name="Access level"
          :value="history.data.access_level_name"
          :timList="history.data.tim_templates"
          :nonAdList="history.data.non_ad_templates"
        ></user-details-row-access>

        <user-details-row-custom label="Custom roles" :roles="history.data.custom_roles"></user-details-row-custom>

        <user-details-row-custom
          v-if="showRequestedCustomRole"
          label="Requested Custom roles"
          :roles="[history.data.requested_custom_role]"
        ></user-details-row-custom>

        <user-details-row attrName="Unique rights" :attrValue="history.data.unique_rights_request"></user-details-row>
        <user-details-row attrName="Comment" :attrValue="history.data.comment"></user-details-row>
      </b-col>
      <b-col md="2" class="order-1 mb-4 mt-3">
        <img src="/img/profile.png" alt="profile pic" class="profilepic" />
      </b-col>
    </b-row>
  </div>
  <div v-else class="text-center text-danger my-2">
    <b-spinner class="align-middle"></b-spinner>
    <strong>Loading...</strong>
  </div>
</template>

<script>
import { formatDate, isObject, capitalize, RequestType } from "../_helpers";
import { mapState } from "vuex";
import UserDetailsRow from "./UserDetailsRow.vue";
import UserDetailsRowCustom from "./UserDetailsRowCustom";
import UserDetailsRowAccess from "./UserDetailsRowAccess";
export default {
  created() {
    if (!this.details) {
      // we didnt get details from props so we load them
      this.getUserHistory();
      this.getLatestUserData();
    }
  },
  props: {
    details: Object,
    masterId: Number,
    showHistory: Boolean,
    showComment: Boolean,
    showTitle: Boolean,
    editable: Boolean
  },
  data() {
    return {
      userHistory: [],
      latestUserData: null,
      currentHistoryIndex: 0,
      loadedHistory: false,
      loadedLatest: false,

      deactivateClicked: false,
      activateClicked: false,
      putClickedOnLeave: false
    };
  },
  computed: {
    ...mapState("settings", ["dateFormat"]),
    modalShow() {
      return this.activateClicked || this.deactivateClicked || this.putClickedOnLeave;
    },
    staticData() {
      return this.activateClicked
        ? {
            modalText: `Reactivate identity for ${this.latestUserData.full_name} (${this.latestUserData.username})?`,
            successMsg: "Activation request has been sent.",
            url: `/identity/reactivate/${this.latestUserData.master_id}/${this.latestUserData.version_no}`
          }
        : this.deactivateClicked
        ? {
            modalText: `Deactivate identity for ${this.latestUserData.full_name} (${this.latestUserData.username})?`,
            successMsg: "Deactivation request has been sent.",
            url: `/identity/deactivate/${this.latestUserData.master_id}/${this.latestUserData.version_no}`
          }
        : this.putClickedOnLeave
        ? {
            modalText: `Put identity ${this.latestUserData.full_name} (${this.latestUserData.username}) on leave?`,
            successMsg: "Leave request has been sent.",
            url: `/identity/put_on_leave/${this.latestUserData.master_id}/${this.latestUserData.version_no}`
          }
        : {};
    },
    showActivateBtn() {
      return (
        (this.latestUserData.user_status.toLowerCase() === "inactive" || this.latestUserData.user_status.toLowerCase() === "leave") &&
        this.editable
      );
    },
    showInactivateBtn() {
      return (
        (this.latestUserData.user_status.toLowerCase() === "active" || this.latestUserData.user_status.toLowerCase() === "leave") &&
        this.latestUserData.end_date !== null &&
        this.editable
      );
    },
    showPutOnLeaveBtn() {
      return (
        this.latestUserData.user_status.toLowerCase() === "active" &&
        this.editable
      );
    },
    history() {
      if (this.details) return this.details;
      return (
        this.historyWithLatest.length > 0 &&
        this.historyWithLatest[this.currentHistoryIndex]
      );
    },
    historyWithLatest() {
      return [{ data: { ...this.latestUserData } }, ...this.userHistory];
    },
    prevDisabled() {
      return this.currentHistoryIndex === this.historyWithLatest.length - 1;
    },
    nextDisabled() {
      return this.currentHistoryIndex === 0;
    },
    detailsTitle() {
      return this.history.request_type
        ? capitalize(this.history.request_type)
        : "Latest";
    },
    detailsMasterId() {
      return this.masterId || this.details.master_id;
    },
    loaded() {
      if (this.details) {
        return true;
      }
      return this.loadedLatest && this.loadedHistory;
    },
    showRequestedCustomRole() {
      return (
        this.details && this.details.request_type === RequestType.CUSTOM_ROLE
      );
    }
  },
  methods: {
    stepPrevHistory() {
      this.currentHistoryIndex++;
    },
    stepNextHistory() {
      this.currentHistoryIndex--;
    },
    stepToLatest() {
      this.currentHistoryIndex = 0;
    },
    getUserHistory() {
      axios.get(`/identity/history/${this.detailsMasterId}`).then(response => {
        this.userHistory = response.data.data;
        this.loadedHistory = true;
      });
    },
    getLatestUserData() {
      axios.get(`/identity/latest/${this.detailsMasterId}`).then(response => {
        //console.log(response.data.data);
        this.latestUserData = response.data.data;
        this.loadedLatest = true;
      });
    },
    formatDate(str) {
      return str ? formatDate(str, this.dateFormat) : "-";
    },
    send() {
      axios
        .post(this.staticData.url, {})
        .then(response => {
          if (response.data.success) {
            this.$bvToast.toast(this.staticData.successMsg, {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true
            });
          }
        })
        .catch(error => {
          console.log(error.response.data);
          if ((error.response.status / 100) % 4 === 0) {
            //bad request
            const errors = error.response.data.errors;
            // show error toast for each error message
            Object.keys(errors).forEach(key => {
              errors[key].forEach(err => {
                this.$bvToast.toast(err, {
                  title: "Error",
                  autoHideDelay: 6000,
                  variant: "danger",
                  solid: true
                });
              });
            });
          } else {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error",
              autoHideDelay: 6000,
              variant: "danger",
              solid: true
            });
          }
        });
    }
  },
  components: {
    UserDetailsRow,
    UserDetailsRowAccess,
    UserDetailsRowCustom
  }
};
</script>

<style scoped lang="css">
.profilepic {
  border-radius: 10px;
  width: 100%;
  max-width: 180px;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
