<template>
  <b-container>
    <h2>Department editor</h2>
    <warning-text></warning-text>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group label="Department name*">
            <b-form-input
              id="dep_name"
              v-model="department_name"
              :state="$v.department_name.$dirty ? !$v.department_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="dep_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Department code*">
            <b-form-input
              id="dep_code"
              v-model="department_code"
              :state="$v.department_code.$dirty ? !$v.department_code.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="dep_code">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing departments</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" class="w-100" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import FormButtons from "../FormButtons";
import Multiselect from "vue-multiselect";
import { deCapitalize } from "../../_helpers/common";
import { FormSelect } from "../../_helpers/models";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import WarningText from "../WarningText";
export default {
  mixins: [validationMixin],
  created() {
    this.getDepartments();
  },
  data() {
    return {
      department_name: "",
      department_code: "",

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "code",
          sortable: true
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listDepartments: [],

      loading: false,
      filter: null,
      editId: null
    };
  },
  validations: {
    department_name: {
      required
    },
    department_code: {
      required
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.getDepartments();
        })
        .catch(error => {
          console.log(error);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.department_name = "";
      this.department_code = "";

      this.filter = null;
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);
      this.department_name = row.name;
      this.department_code = row.code;
    },
    getDepartments() {
      this.loading = true;
      axios.get("department/list").then(response => {
        this.listDepartments = response.data.data;
        this.loading = false;
        this.tableData = this.listDepartments;
      });
    }
  },
  computed: {
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },
    postData() {
      return {
        name: this.department_name,
        code: this.department_code
      };
    },
    postUrl() {
      return this.editId ? `/department/edit/${this.editId}` : "/department/create";
    },
    successMsg() {
      return this.editId ? "Department modification OK" : "New department added";
    },
    formTitle() {
      return this.editId ? "Edit department" : "Add new department";
    }
  },
  components: {
    FormButtons,
    Multiselect,
    FilterInput,
    WarningText
  }
};
</script>
<style scoped>
h4 {
  margin-bottom: 2rem;
}
</style>