<template>
  <div>
    <b-card title="Search" align="left" class="mb-4">
      <b-form @submit="handleSearch" @reset="handleReset" v-if="show">
        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Name" label-for="full_name">
              <b-form-input id="full_name" v-model="filters.full_name.value"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Username" label-for="username">
              <b-form-input id="username" v-model="filters.username" trim></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Organizational unit">
              <b-form-input
                id="organizational_unit_name"
                v-model="filters.organizational_unit_name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Department">
              <b-form-input id="department_name" v-model="filters.department_name" trim></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="EA Entity">
              <b-form-select v-model="filters.company_id.value" :options="companyOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="External company">
              <b-form-select
                v-model="filters.external_company_id.value"
                :options="externalCompanyOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Status">
              <b-form-select
                @change="handleStatusChange"
                v-model="filters.user_status_id.value"
                :options="statusOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Direct manager">
              <b-form-input v-model="filters.direct_manager_full_name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Tax ID">
              <b-form-input v-model="filters.tax_id.value" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Access level">
              <b-form-select v-model="filters.access_level_id.value" :options="accessLevelOptions"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Employment type">
              <b-form-select
                v-model="filters.employment_type_id.value"
                :options="employmentTypeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group description label="Primary role">
              <b-form-select v-model="filters.primary_role_id.value" :options="primaryRoleOptions"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="Created (From)">
              <custom-datepicker v-model="filters.created_from.value"></custom-datepicker>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="Created (To)">
              <custom-datepicker v-model="filters.created_to.value" toggle-left></custom-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="Start date (From)">
              <custom-datepicker v-model="filters.start_date_from.value"></custom-datepicker>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="Start date (To)">
              <custom-datepicker v-model="filters.start_date_to.value" toggle-left></custom-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="End date (From)">
              <custom-datepicker v-model="filters.end_date_from.value"></custom-datepicker>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="End date (To)">
              <custom-datepicker v-model="filters.end_date_to.value" toggle-left></custom-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="6" lg="12" md="6">
            <b-form-group label="Apply search at this date">
              <custom-datepicker v-model="searchDateAt" @input="handleApplyDateSelected"></custom-datepicker>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="12" md="6"></b-col>
        </b-row>

        <div class="mt-4">
          <b-button type="reset" variant="secondary" class="mr-2">Reset</b-button>
          <b-button type="submit" variant="primary">
            <font-awesome-icon icon="search" class="mr-2"></font-awesome-icon>Search
          </b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import CustomDatepicker from "./CustomDatePicker";
import { mapGetters, mapState, mapActions } from "vuex";
import { isSameDay } from "date-fns";

export default {
  created() {
    this.getFormData();
  },
  computed: {
    state() {
      return {
        name: this.name.length > 0 ? this.name.length >= 4 : null
      };
    }
  },
  data() {
    return {
      filters: {
        full_name: {
          value: "",
          // every word in the query must be included in the item's value for a match
          matchMethod: function(itemVal) {
            return !this.value
              .split(" ")
              .map(w => w.toLowerCase())
              .find(word => !itemVal.toLowerCase().includes(word));
          }
        },
        username: "",
        organizational_unit_name: "",
        department_name: "",
        direct_manager_full_name: "",
        company_id: {
          exact: true,
          value: ""
        },
        external_company_id: {
          value: "",
          exact: true
        },
        user_status_id: {
          exact: true,
          value: 1
        },
        tax_id: {
          value: "",
          matchMethod: function(itemVal) {
            return itemVal
              .replace(/[\W_]+/g, "")
              .includes(this.value.replace(/[\W_]+/g, ""));
          }
        },
        access_level_id: {
          value: "",
          exact: true
        },
        employment_type_id: {
          exact: true,
          value: ""
        },
        primary_role_id: {
          exact: true,
          value: ""
        },
        created_from: {
          value: "",
          key: "created_at",
          matchMethod: function(itemVal) {
            return new Date(this.value) <= new Date(itemVal);
          }
        },
        created_to: {
          value: "",
          key: "created_at",
          matchMethod: function(itemVal) {
            return new Date(this.value) >= new Date(itemVal);
          }
        },
        start_date_from: {
          value: "",
          key: "start_date",
          matchMethod: function(itemVal) {
            return new Date(this.value) <= new Date(itemVal);
          }
        },
        start_date_to: {
          value: "",
          key: "start_date",
          matchMethod: function(itemVal) {
            return new Date(this.value) >= new Date(itemVal);
          }
        },
        end_date_from: {
          value: "",
          key: "end_date",
          matchMethod: function(itemVal) {
            return new Date(this.value) <= new Date(itemVal);
          }
        },
        end_date_to: {
          value: "",
          key: "end_date",
          matchMethod: function(itemVal) {
            return new Date(this.value) >= new Date(itemVal);
          }
        }
      },
      show: true,
      searchDateAt: "",
      searchDateFrom: "",
      searchDateTo: "",
      allUserHistory: []
    };
  },
  computed: {
    ...mapGetters("identities", [
      "companyOptions",
      "externalCompanyOptions",
      "employmentTypeOptions",
      "primaryRoleOptions",
      "accessLevelOptions",
      "statusOptions"
    ]),
    ...mapState("identities", ["mainTableItems"])
  },
  methods: {
    ...mapActions("identities", ["stopLoading", "startLoading"]),
    getAllHistory() {
      axios.get("/identity/history/all").then(response => {
        console.log(response.data.data);
        this.allUserHistory = response.data.data;
      });
    },

    // returns true if a user record matches the specified search filters
    matchUser(item) {
      const filterKeys = Object.keys(this.filters);
      //console.log("filters: " + JSON.stringify(this.filters));

      return filterKeys.every(key => {
        // if the current filter is empty we ignore it
        if (
          (typeof this.filters[key] === "string" && this.filters[key].length === 0) ||
          (typeof this.filters[key] === "object" && this.filters[key].value.length === 0)
        )
          return true;

        // from here we have a valid, non empty filter

        const searchVal = this.filters[key];
        const itemVal = searchVal.hasOwnProperty("key") ? item[searchVal.key] : item[key];

        console.log(
          "key: " +
            key +
            " itemVal: " +
            itemVal +
            " searchVal: " +
            JSON.stringify(searchVal)
        );
        if (typeof this.filters[key] === "object") {
          // if the property has a custom filter method we use that
          if (searchVal.hasOwnProperty("matchMethod")) {
            console.log("matchMethod");

            return searchVal.matchMethod(itemVal);
          }
          // check if field requires exact match
          else if (searchVal.hasOwnProperty("exact") && searchVal.exact) {
            console.log("exact");
            return searchVal.value === itemVal;
          }
        }

        // the default match method
        console.log("default");
        return typeof searchVal === "object"
          ? itemVal && itemVal.toLowerCase().includes(searchVal.value.toLowerCase())
          : itemVal && itemVal.toLowerCase().includes(searchVal.toLowerCase());
      });
    },

    handleApplyDateSelected() {
      // if apply date is selected request all history data of users
      this.getAllHistory();
    },

    getLatestApplicableRevision(userHistory) {
      let idx = 0;
      const searchDate = new Date(this.searchDateAt);
      //console.log(JSON.stringify(userHistory, null, 3));

      let rev = undefined;

      // find the index of the first revision that is later then the applied search date
      // the index before this one will hold the revision that applies to the user at the the given date
      for (let i = 0; i < userHistory.length; i++) {
        let revCreatedAt = new Date(userHistory[i].created_at);
        if (revCreatedAt < searchDate || isSameDay(revCreatedAt, searchDate)) {
          rev = userHistory[i];
        }
      }

      //console.log(rev);
      return rev;
    },

    searchInHistory() {
      const results = [];
      this.allUserHistory.forEach(userHistory => {
        const latestRev = this.getLatestApplicableRevision(userHistory);
        if (latestRev && this.matchUser(latestRev)) {
          results.push(latestRev);
        }
      });
      return results;
    },

    handleSearch(e) {
      e.preventDefault();

      const filterKeys = Object.keys(this.filters);
      //console.log("filters: " + JSON.stringify(this.filters));

      let searchResults = [];

      // if the search is restricted to a given date
      if (this.searchDateAt) {
        searchResults = this.searchInHistory();
        // console.log(
        //   "searchInHistory results: " + JSON.stringify(searchResults, null, 3)
        // );
      } else {
        // loop through every item and check them against every filter
        searchResults = this.mainTableItems.filter(item => {
          return this.matchUser(item);
        });
        // console.log(
        //   "normal search results: " + JSON.stringify(searchResults, null, 3)
        // );
      }

      this.$store.commit("identities/SET_SEARCH_RESULTS", searchResults);
    },

    handleReset() {
      this.$store.commit("identities/RESET_SEARCH");

      // reset table data to show active identities
      if (
        this.filters.user_status_id.value !== "" &&
        this.filters.user_status_id.value !== 1 // id 1 is Active status
      ) {
        console.log("resetting table data");
        this.handleStatusChange(1); // id 1 is Active status
      }

      // reset filters to empty values
      Object.keys(this.filters).forEach(key => {
        if (typeof this.filters[key] === "object") {
          this.filters[key].value = "";
        } else {
          this.filters[key] = "";
        }
      });

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    // if the status changes we reload the table data according to the selected value
    handleStatusChange(value) {
      const status = this.statusOptions
        .find(opt => opt.value === value)
        .text.toLowerCase();
      this.startLoading();
      console.log("status changed, requesting data: " + status);
      axios.get(`/identity/live/${status}`).then(response => {
        this.stopLoading();
        this.$store.dispatch("identities/addIdentities", response.data.data);
      });
    },
    // get options for dropdown menus
    getFormData() {
      axios.get("/data/identity/search").then(response => {
        this.$store.commit("identities/SET_OPTIONS", response.data.data);
      });
    }
  },
  components: {
    CustomDatepicker
  }
};
</script>