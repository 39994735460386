<template>
  <div>
    <b-container>
      <h2>Settings</h2>

      <b-row class="mt-3 align-items-end">
        <b-col md="4" lg="3" sm="12">
          <b-form-group label="Date format">
            <b-form-select
              v-model="dateFormat"
              @change="submitSettings"
              :options="dateFormatOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4" lg="2" sm="12">
          <b-form-group label="Time format">
            <b-form-select
              v-model="timeFormat"
              @change="submitSettings"
              :options="timeFormatOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4" lg="2" sm="12">
          <b-form-group label="First day of week">
            <b-form-select
              v-model="mondayFirst"
              @change="submitSettings"
              :options="mondayFirstOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="4" lg="3" sm="12">
          <p>
            <i>Example:</i>
            {{ formatDate(new Date(2069,3,20,13,37).toISOString()) }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { formatDate } from "../_helpers";
import { settings } from "../_store/settings.module";
export default {
  computed: {
    ...mapGetters("settings", [
      "dateFormatOptions",
      "timeFormatOptions",
      "mondayFirstOptions",
      "dateTimeFormat"
    ]),

    dateFormat: {
      get() {
        return this.$store.state.settings.dateFormat;
      },
      set(value) {
        this.$store.commit("settings/SET_DATE_FORMAT", value);
      }
    },
    timeFormat: {
      get() {
        return this.$store.state.settings.timeFormat;
      },
      set(value) {
        this.$store.commit("settings/SET_TIME_FORMAT", value);
      }
    },
    mondayFirst: {
      get() {
        return this.$store.state.settings.mondayFirst;
      },
      set(value) {
        return this.$store.commit("settings/SET_MONDAY_FIRST", value);
      }
    }
  },
  methods: {
    submitSettings() {
      axios
        .post("settings/save", {
          dateFormat: this.dateFormat,
          timeFormat: this.timeFormat,
          mondayFirst: this.mondayFirst
        })
        .then(response => console.log(response));
    },
    formatDate(dateStr) {
      return formatDate(dateStr, this.dateTimeFormat);
    }
  }
};
</script>