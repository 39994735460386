<template>
  <b-row class="justify-content-center">
    <b-col xl="8" lg="9" md="10" sm="12">
      <transition name="slide-fade" mode="out-in">
        <b-card v-if="step === 1" align="left" :title="heading">
          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Surname (Last name)*">
                <b-form-input
                  @change="getEmail"
                  v-model="last_name"
                  :state="$v.last_name.$dirty ? !$v.last_name.$error : null"
                ></b-form-input>
                <b-form-invalid-feedback>
                  <div v-if="!$v.last_name.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="Given name (First name)*">
                <b-form-input
                  @change="getEmail"
                  v-model="first_name"
                  :state="$v.first_name.$dirty ? !$v.first_name.$error : null"
                ></b-form-input>
                <b-form-invalid-feedback>
                  <div v-if="!$v.first_name.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Employment type*">
                <b-form-select
                  v-model="employment_type_id"
                  @change="getEmail"
                  :options="employmentTypeOptions"
                  :state="
                    $v.employment_type_id.$dirty ? !$v.employment_type_id.$error : null
                  "
                ></b-form-select>
                <b-form-invalid-feedback>
                  <div v-if="!$v.employment_type_id.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group class="flex-wrap" label="Email address">
                <b-input-group :append="emailDomain" :state="!emailTaken ? null : false">
                  <b-input-group-prepend v-if="emailTaken">
                    <b-button
                      @click="setEmailEditable(true)"
                      size="small"
                      variant="outline-secondary"
                    >Edit</b-button>
                  </b-input-group-prepend>

                  <b-form-input
                    id="input-email"
                    :style="{ 'text-align': 'right' }"
                    v-model="emailLocal"
                    :readonly="!emailEditable"
                    :state="!emailTaken ? null : false"
                    @input="onEmailChange"
                  ></b-form-input>
                  <b-form-invalid-feedback>Email already exists</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="EA entity*">
                <b-form-select
                  v-model="company_id"
                  :options="companyOptions"
                  :state="$v.company_id.$dirty ? !$v.company_id.$error : null"
                ></b-form-select>
                <b-form-invalid-feedback>
                  <div v-if="!$v.company_id.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group v-if="externalSelected" label="External company*">
                <b-form-select
                  v-model="external_company_id"
                  :options="externalCompanyOptions"
                  :state="
                    $v.external_company_id.$dirty ? !$v.external_company_id.$error : null
                  "
                ></b-form-select>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Location/Office*">
                <b-form-select
                  v-model="location_id"
                  :options="locationOptions"
                  :state="$v.location_id.$dirty ? !$v.location_id.$error : null"
                ></b-form-select>
                <b-form-invalid-feedback>
                  <div v-if="!$v.location_id.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Prefix">
                <b-form-input v-model="title" placeholder="e.g. Dr"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="Tax ID*">
                <b-form-input
                  id="taxid"
                  v-model="tax_id"
                  :state="
                    taxIdUnique && ($v.tax_id.$dirty ? !$v.tax_id.$error : true)
                      ? null
                      : false
                  "
                  @input="onTaxIdChange"
                ></b-form-input>
                <b-form-invalid-feedback>
                  <div v-if="!taxIdUnique">Tax ID already exists</div>
                  <div v-if="!$v.tax_id.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Start date*">
                <custom-datepicker v-model="start_date" :only-future-dates="futureDatesOnly"></custom-datepicker>
                <small
                  v-if="$v.start_date.$dirty && !$v.start_date.required"
                  class="text-danger"
                >Field is required</small>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="End date">
                <custom-datepicker v-model="end_date" :only-future-dates="futureDatesOnly"></custom-datepicker>
                <small
                  v-if="$v.end_date.$dirty && !$v.end_date.requiredIf"
                  class="text-danger"
                >Field is required</small>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Last workday">
                <custom-datepicker v-model="last_workday" :only-future-dates="futureDatesOnly"></custom-datepicker>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="Primary role*">
                <b-form-select
                  v-model="primary_role"
                  :options="primaryRoleOptions"
                  :state="$v.primary_role.$dirty ? !$v.primary_role.$error : null"
                ></b-form-select>
                <b-form-invalid-feedback>
                  <div v-if="!$v.primary_role.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6">
              <b-form-group label="Access level*">
                <b-form-select
                  v-model="primary_role_access_level_id"
                  :options="accessLevelOptions"
                  :state="
                    $v.primary_role_access_level_id.$dirty
                      ? !$v.primary_role_access_level_id.$error
                      : null
                  "
                ></b-form-select>
                <b-form-invalid-feedback>
                  <div v-if="!$v.primary_role_access_level_id.required">Field is required</div>
                </b-form-invalid-feedback>
              </b-form-group>
              <template v-if="this.timListPrimary.length">
                <small>
                  TIM templates defined by the selected primary role and access level
                  combination:
                  <ul>
                    <li v-for="tim in timListPrimary" :key="tim.id">
                      <tim :tim="tim"></tim>
                    </li>
                  </ul>
                </small>
              </template>
              <template v-if="this.nonAdListPrimary.length">
                <small>
                  Non-AD templates defined by the selected primary role and access level
                  combination:
                  <ul>
                    <li v-for="nonAd in nonAdListPrimary" :key="nonAd.id">
                      <tim :tim="nonAd"></tim>
                    </li>
                  </ul>
                </small>
              </template>
            </b-col>
            <b-col lg="6">
              <b-form-group label="Predefined custom roles">
                <multiselect
                  v-model="custom_roles"
                  :multiple="true"
                  track-by="id"
                  label="name"
                  openDirection="bottom"
                  :options="predefinedCustomRoleOptions"
                ></multiselect>
              </b-form-group>
              <template v-if="this.timListCustom.length">
                <small>
                  TIM templates defined by custom roles:
                  <ul>
                    <li v-for="tim in timListCustom" :key="tim.id">
                      <tim :tim="tim"></tim>
                    </li>
                  </ul>
                </small>
              </template>
              <template v-if="this.nonAdListCustom.length">
                <small>
                  Non-AD templates defined by custom roles:
                  <ul>
                    <li v-for="nonAd in nonAdListCustom" :key="nonAd.id">
                      <tim :tim="nonAd"></tim>
                    </li>
                  </ul>
                </small>
              </template>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Department">
                <b-form-input disabled v-model="departmentName"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group label="Status*">
                <b-form-select
                  v-model="user_status"
                  :options="statusOptions"
                  :disabled="isNewRequest"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col lg="6" sm="12">
              <b-form-group label="Organizational unit">
                <b-form-input disabled v-model="organizationName"></b-form-input>
              </b-form-group>

              <b-form-group label="Direct manager">
                <b-form-input disabled v-model="manager"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="6" sm="12">
              <b-form-group label="Unique rights">
                <b-form-textarea
                  placeholder="Describe in detail what other rights should be provided"
                  rows="4"
                  v-model="unique_rights_request"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </div>

          <b-col class="d-flex mt-3 justify-content-end">
            <b-button variant="secondary" class="mr-4" @click="prev">Cancel</b-button>
            <b-button variant="primary" @click="next">
              <font-awesome-icon icon="forward" class="mr-2" />Preview
            </b-button>
          </b-col>
        </b-card>

        <!-- show preview page if button clicked-->
        <preview v-else-if="step === 2" @success="handleSuccess"></preview>
      </transition>
    </b-col>
  </b-row>
</template>
<script>
import CustomDatepicker from "../components/CustomDatePicker";
import Preview from "./IdentityPreview";
import Multiselect from "vue-multiselect";
import Tim from "../components/TIMrow";
import { mapState, mapGetters } from "vuex";
import { authenticationService } from "../_services";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  components: {
    CustomDatepicker,
    Multiselect,
    Preview,
    Tim
  },
  created() {
    this.getFormData();
    if (this.$route.name === "editIdentity") {
      // we are on the edit page, get the user data
      this.getRevisionData();
    }
  },

  data() {
    return {
      emailEditable: false,
      taxIdUnique: true
    };
  },
  validations: {
    last_name: {
      required
    },
    first_name: {
      required
    },
    employment_type_id: {
      required
    },
    company_id: {
      required
    },
    location_id: {
      required
    },
    external_company_id: {
      requiredIf: requiredIf(function() {
        return this.externalSelected;
      })
    },
    tax_id: {
      required
    },
    start_date: {
      required
    },
    end_date: {
      requiredIf: requiredIf(function() {
        return this.inactiveSelected;
      })
    },
    primary_role_access_level_id: {
      required
    },
    primary_role: {
      required
    }
  },
  computed: {
    ...mapState("requestform", ["step", "emailTaken"]),
    ...mapGetters("requestform", [
      "companyOptions",
      "locationOptions",
      "externalCompanyOptions",
      "employmentTypeOptions",
      "primaryRoleOptions",
      "statusOptions",
      "predefinedCustomRoleOptions",
      "accessLevelOptions"
    ]),
    last_name: {
      get() {
        return this.$store.state.requestform.item.last_name || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "last_name",
          value
        });
      }
    },
    first_name: {
      get() {
        return this.$store.state.requestform.item.first_name;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "first_name",
          value
        });
      }
    },
    title: {
      get() {
        return this.$store.state.requestform.item.title;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "title",
          value
        });
      }
    },
    company_id: {
      get() {
        return this.$store.state.requestform.item.company_id || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "company_id",
          value
        });
      }
    },
    location_id: {
      get() {
        return this.$store.state.requestform.item.location_id || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "location_id",
          value
        });
      }
    },
    external_company_id: {
      get() {
        return this.$store.state.requestform.item.external_company_id || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "external_company_id",
          value
        });
      }
    },
    tax_id: {
      get() {
        return this.$store.state.requestform.item.tax_id;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "tax_id",
          value
        });
      }
    },
    start_date: {
      //todo dates are incorrect when editing
      get() {
        return this.$store.state.requestform.item.start_date;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "start_date",
          value
        });
      }
    },
    end_date: {
      //todo dates are incorrect when editing
      get() {
        return this.$store.state.requestform.item.end_date;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "end_date",
          value
        });
      }
    },
    last_workday: {
      //todo dates are incorrect when editing
      get() {
        return this.$store.state.requestform.item.last_workday;
      },
      set(value) {
        console.log("last_workday setter: " + value);
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "last_workday",
          value
        });
      }
    },
    user_status: {
      get() {
        return this.isNewRequest
          ? 1 // active
          : this.$store.state.requestform.item.user_status_id || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "user_status_id",
          value
        });
      }
    },
    employment_type_id: {
      get() {
        return this.$store.state.requestform.item.employment_type_id || "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "employment_type_id",
          value
        });
        // if employment type is employee we reset the external company
        if (value === 1) {
          this.$store.commit("requestform/UPDATE_ITEM", {
            key: "external_company_id",
            value: null
          });
        }
      }
    },
    primary_role: {
      get() {
        // primary options list may have changed due to EA entity being changed and therefore
        // the current options might not contain the preiously set value, so we check it here
        const pr_id = this.$store.state.requestform.item.primary_role_id;
        return this.primaryRoleOptions.find(prOpt => prOpt.value === pr_id) ? pr_id : "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "primary_role_id",
          value
        });
      }
    },
    custom_roles: {
      get() {
        // prevent rejected custom roles from being shown
        return this.$store.state.requestform.item.custom_roles
          ? this.$store.state.requestform.item.custom_roles.filter(
              role => role.approval_status !== "Rejected"
            )
          : [];
      },
      set(value) {
        // prevent rejected custom roles from being set
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "custom_roles",
          value: value.filter(role => role.approval_status !== "Rejected")
        });
      }
    },
    unique_rights_request: {
      get() {
        return this.$store.state.requestform.item.unique_rights_request;
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "unique_rights_request",
          value
        });
      }
    },
    primary_role_access_level_id: {
      get() {
        // access level options list may have changed due to primary role options being changed and therefore
        // the current options might not contain the preiously set value, so we check that here
        const pral_id = this.$store.state.requestform.item.primary_role_access_level_id;
        return this.accessLevelOptions.find(acOpt => acOpt.value === pral_id)
          ? pral_id
          : "";
      },
      set(value) {
        this.$store.commit("requestform/UPDATE_ITEM", {
          key: "primary_role_access_level_id",
          value
        });
      }
    },
    //non editable
    departmentName() {
      const found = this.primaryRoleOptions.find(opt => opt.value === this.primary_role);
      return found && found.value
        ? `${found.department_name} (${found.department_code})`
        : "";
    },
    //non editable
    organizationName() {
      const found = this.primaryRoleOptions.find(opt => opt.value === this.primary_role);
      return found && found.value
        ? `${found.org_unit_name} (${found.org_unit_code})`
        : "";
    },
    //non editable
    manager() {
      const found = this.primaryRoleOptions.find(opt => opt.value === this.primary_role);
      return found && found.value
        ? `${found.manager_name} (${found.manager_username})`
        : "";
    },
    email() {
      return this.emailLocal ? `${this.emailLocal}${this.emailDomain}` : "";
    },
    emailDomain() {
      if (this.$store.state.requestform.item.email) {
        return `@${this.$store.state.requestform.item.email.split("@")[1]}`;
      }
      return "";
    },
    emailLocal: {
      get() {
        if (this.$store.state.requestform.item.email) {
          return this.$store.state.requestform.item.email.split("@")[0];
        }
        return "";
      }
    },

    externalSelected() {
      return !this.employmentTypeOptions
        ? false
        : this.employmentTypeOptions
            .filter(opt => opt.contract_type === "External")
            .map(opt => opt.value)
            .includes(this.employment_type_id);
    },
    inactiveSelected() {
      return !this.statusOptions
        ? false
        : this.statusOptions
            .filter(opt => opt.text === "Inactive")
            .map(opt => opt.value)
            .includes(this.user_status);
    },
    heading() {
      return this.isNewRequest ? "New identity request" : "Identity modification request";
    },
    isNewRequest() {
      return this.$route.name === "newIdentity";
    },
    futureDatesOnly() {
      return !(authenticationService.isHR() || (authenticationService.isManager() && this.isNewRequest));
    },
    timListPrimary() {
      if (
        // not loaded yet
        !this.$store.state.requestform.item.primary_role_id ||
        !this.$store.state.requestform.item.primary_role_access_level_id ||
        !this.$store.state.requestform.identityCreationOptions
      ) {
        return [];
      }
      const selectedPrimaryRoleAccessLevelId = this.$store.state.requestform.item
        .primary_role_access_level_id;
      console.log("selectedPrimaryRoleAccessLevelId", selectedPrimaryRoleAccessLevelId);
      let accessLevel = this.$store.state.requestform.identityCreationOptions.primary_roles
        .flatMap(role => role.access_levels)
        .find(
          level => level.primary_role_access_level_id === selectedPrimaryRoleAccessLevelId
        );

      if (!accessLevel) {
        console.log("no access level found, returning empty TIM list");
        return [];
      } else {
        return accessLevel.tim_templates.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    nonAdListPrimary() {
        if (
            // not loaded yet
            !this.$store.state.requestform.item.primary_role_id ||
            !this.$store.state.requestform.item.primary_role_access_level_id ||
            !this.$store.state.requestform.identityCreationOptions
        ) {
            return [];
        }
        const selectedPrimaryRoleAccessLevelId = this.$store.state.requestform.item
            .primary_role_access_level_id;
        console.log("selectedPrimaryRoleAccessLevelId", selectedPrimaryRoleAccessLevelId);
        let accessLevel = this.$store.state.requestform.identityCreationOptions.primary_roles
            .flatMap(role => role.access_levels)
            .find(
                level => level.primary_role_access_level_id === selectedPrimaryRoleAccessLevelId
            );

        if (!accessLevel) {
            console.log("no access level found, returning empty Non-AD list");
            return [];
        } else {
            return accessLevel.non_ad_templates.sort((a, b) => a.name.localeCompare(b.name));
        }
    },

    timListCustom() {
      if (
        // not loaded yet
        !this.$store.state.requestform.item.custom_roles ||
        !this.$store.state.requestform.identityCreationOptions
      ) {
        return [];
      }

      const selectedCustomRoleIds = this.$store.state.requestform.item.custom_roles
        .filter(role => role.approval_status !== "Rejected") // do not show tim templates for rejected custom role requests
        .map(role => role.id);

      if (selectedCustomRoleIds.length === 0) {
        return [];
      }
      const allTims = this.$store.state.requestform.identityCreationOptions.custom_roles
        .filter(role => selectedCustomRoleIds.includes(role.id))
        .flatMap(role => role.tim_templates);

      if (allTims.length === 0) {
        return [];
      }
      const uniqueTims = [];

      const map = new Map();
      allTims.forEach(tim => {
        if (!map.has(tim.id)) {
          map.set(tim.id, true); // set any value to map
          uniqueTims.push(tim);
        }
      });

      return uniqueTims.sort((a, b) => a.name.localeCompare(b.name));
    },
    
    nonAdListCustom() {
      if (
        // not loaded yet
        !this.$store.state.requestform.item.custom_roles ||
        !this.$store.state.requestform.identityCreationOptions
      ) {
        return [];
      }

      const selectedCustomRoleIds = this.$store.state.requestform.item.custom_roles
        .filter(role => role.approval_status !== "Rejected") // do not show non-ad templates for rejected custom role requests
        .map(role => role.id);

      if (selectedCustomRoleIds.length === 0) {
        return [];
      }
      const allTims = this.$store.state.requestform.identityCreationOptions.custom_roles
        .filter(role => selectedCustomRoleIds.includes(role.id))
        .flatMap(role => role.non_ad_templates);

      if (allTims.length === 0) {
        return [];
      }
      const uniqueTims = [];

      const map = new Map();
      allTims.forEach(tim => {
        if (!map.has(tim.id)) {
          map.set(tim.id, true); // set any value to map
          uniqueTims.push(tim);
        }
      });

      return uniqueTims.sort((a, b) => a.name.localeCompare(b.name));
    }
  },

  methods: {
    getRevisionData() {
      axios
        .get(
          `/identity/revision/${this.$route.params.master_id}/${this.$route.params.version_no}`
        )
        .then(response => {
          if (response.data.success) {
            this.$store.commit("requestform/SET_PREFILLED_ITEM", response.data.data);
            // clear unique rights request field since we are on the edit page and it's not needed
            this.$store.commit("requestform/UPDATE_ITEM", {
              key: "unique_rights_request",
              value: ""
            });
          } else {
            console.error("Error fetching revision data");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getEmail() {
      if (this.employment_type_id && this.last_name && this.first_name) {
        const body = {
          first_name: this.first_name,
          last_name: this.last_name,
          employment_type_id: this.employment_type_id
        };
        const url = this.isNewRequest
          ? "/email/generate"
          : `/email/generate/except/${this.$store.state.requestform.item.master_id}`;
        axios.post(url, body).then(response => {
          console.log(response.data.data);
          if (response.status === 200) {
            //this.emailData = response.data.data;
            this.$store.commit("requestform/UPDATE_ITEM", {
              key: "email",
              value: `${response.data.data.local_part}@${response.data.data.domain}`
            });
            this.$store.commit(
              "requestform/SET_EMAIL_TAKEN",
              response.data.data.local_part === ""
            );

            this.$store.commit("requestform/UPDATE_ITEM", {
              key: "email",
              value: this.email
            });
          }
        });
      } else {
        console.log("not enough data to request email");
      }
    },
    setEmailEditable(bool) {
      this.emailEditable = bool;
    },
    onEmailChange(newVal) {
      this.$store.commit("requestform/UPDATE_ITEM", {
        key: "email",
        value: `${newVal}${this.emailDomain}`
      });
      const url = this.isNewRequest
        ? "/email/unique"
        : `/email/unique/except/${this.$store.state.requestform.item.master_id}`;
      axios
        .post(url, {
          email: `${newVal}${this.emailDomain}`
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            console.log("set email taken");
            this.$store.commit(
              "requestform/SET_EMAIL_TAKEN",
              !response.data.data.is_unique
            );
          } else {
            console.error("Error fetching email unique check");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    onTaxIdChange(tax_id) {
      const url = this.isNewRequest
        ? "/tax_id/unique"
        : `/tax_id/unique/except/${this.$store.state.requestform.item.master_id}`;
      axios.post(url, { tax_id }).then(response => {
        this.taxIdUnique = response.data.data.is_unique;
      });
    },
    getFormData() {
      // different data sets for dropdowns depending on new new vs edit request is being made
      const url = this.isNewRequest
        ? "/data/identity/creation"
        : `/data/identity/modification/${this.$route.params.id}`;

      axios
        .get(url)
        .then(response => {
          if (response.data.success) {
            this.$store.commit("requestform/SET_FORM_OPTIONS", response.data.data);
          } else {
            console.error("Error fetching form options");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    handleSuccess(message) {
      // reset form validators
      this.$v.$reset();
      this.$bvToast.toast(message, {
        title: "Success",
        autoHideDelay: 5000,
        variant: "success",
        solid: true
      });
    },

    next(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        console.log("form validation error");
        return;
      }

      this.$store.dispatch("requestform/stepNext");
    },
    prev() {
      this.$store.dispatch("requestform/stepPrev");
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
