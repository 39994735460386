<template>
  <div>
    <span :class="extraClass">
      {{ printVal(value) }}
      <b-badge
        v-if="badge"
        :title="badge"
        class="custombadge"
        :variant="badgeVariant"
      >{{badge.charAt(0)}}</b-badge>
    </span>
    <small>
      <ul v-if="hasTimList">
        <template v-if="timList.length > 0">TIM</template>
        <li v-for="tim in timList" :key="tim.id">
          <tim :tim="tim"></tim>
        </li>
      </ul>
    </small>
    <small>
      <ul v-if="hasNonAdList">
        <template v-if="nonAdList.length > 0">Non-AD</template>
        <li v-for="nonAd in nonAdList" :key="nonAd.id">
          <tim :tim="nonAd"></tim>
        </li>
      </ul>
    </small>
  </div>
</template>
<script>
import { formatDate, getWeekDayName } from "../_helpers/common";
import { mapState, mapGetters } from "vuex";
import Tim from "./TIMrow";
export default {
  props: {
    value: String | Number,
    date: Boolean,
    datetime: Boolean,
    extraClass: String | Array,
    hasTimList: Boolean,
    timList: Array,
    hasNonAdList: Boolean,
    nonAdList: Array,
    badge: String
  },

  computed: {
    ...mapState("settings", ["dateFormat"]),
    ...mapGetters("settings", ["dateTimeFormat"]),
    title() {
      return (this.date || this.datetime) && getWeekDayName(new Date(this.value));
    },
    badgeVariant() {
      switch (this.badge.toLowerCase()) {
        case "rejected":
          return "danger";
        case "approved":
          return "success";
        case "pending":
          return "primary";
        default:
          return "secondary";
      }
    }
  },
  methods: {
    printVal(value) {
      if (value) {
        if (this.datetime) {
          return `${formatDate(value, this.dateTimeFormat)} (${getWeekDayName(
            new Date(value)
          )})`;
        }
        if (this.date) {
          return `${formatDate(value, this.dateFormat)} (${getWeekDayName(
            new Date(value)
          )})`;
        }
        if (typeof value === "object") {
          return value.name;
        }
        return value;
      }
      return "-";
    }
  },
  components: {
    Tim
  }
};
</script>
<style lang="css" scoped>
.custombadge {
  cursor: help;
}
</style>
