<template>
  <div class="mt-4">
    <b-button type="reset" variant="secondary" class="mr-2">Reset</b-button>
    <b-button type="submit" variant="primary" :disabled="disabled">
      <font-awesome-icon :icon="actionIcon" class="mr-2"></font-awesome-icon>
      {{ actionLabel }}
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    icon: String,
    disabled: Boolean
  },
  computed: {
    actionIcon() {
      return this.icon || "save";
    },
    actionLabel() {
      return this.label || "Save";
    }
  }
};
</script>