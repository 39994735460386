export const settings = {
  namespaced: true,
  state: {
    dateFormats: [
      "MM/dd/yyyy",
      "dd/MM/yyyy",
      "yyyy-MM-dd",
      "yyyy MMM. dd.",
      "yyyy MMMM dd.",
      "MMM. dd, yyyy",
      "MMMM dd, yyyy",
      "dd MMM. yyyy",
      "dd MMMM yyyy"
    ],
    timeFormats: ["HH:mm", "H:mm", "h:mm a"],

    // set by user
    dateFormat: "yyyy-MM-dd",
    timeFormat: "H:mm",
    mondayFirst: true
  },
  mutations: {
    SET_DATE_FORMAT(state, format) {
      state.dateFormat = format;
    },
    SET_TIME_FORMAT(state, format) {
      state.timeFormat = format;
    },
    SET_MONDAY_FIRST(state, isMondayFirst) {
      state.mondayFirst = isMondayFirst;
    },
    SET_OPTIONS(state, options) {
      Object.keys(options).forEach(key => (state[key] = options[key]));
    }
  },
  actions: {
    setOptions({ commit }, options) {
      commit("SET_OPTIONS", options);
    }
  },
  getters: {
    dateFormatOptions: state => {
      return state.dateFormats.map(f => ({
        text: f,
        value: f
      }));
    },
    timeFormatOptions: state => {
      return state.timeFormats.map(f => ({
        text: f,
        value: f
      }));
    },
    mondayFirstOptions: state => {
      return [{ value: true, text: "Monday" }, { value: false, text: "Sunday" }];
    },
    dateTimeFormat: state => {
      return `${state.dateFormat} ${state.timeFormat}`;
    }
  }
};
