
<template>
  <b-container>
    <div v-if="request">
      <h3 class="mb-3">{{ title }}</h3>
      <identity-card
        :id-request="request"
        :editable="editable"
        @approve="handleApprove"
        @reject="handleReject"
      ></identity-card>
    </div>
    <div v-else-if="notFound">
      <h2>Request not found</h2>
    </div>
  </b-container>
</template>
<script>
import IdentityCard from "../components/IdentityCard";
import { capitalize } from "../_helpers";
import { mapState } from "vuex";

export default {
  created() {
    this.getRequest();
  },
  data() {
    return {
      request: undefined,
      notFound: false
    };
  },
  computed: {
    ...mapState("authentication", ["user"]),
    title() {
      return this.$route.name === "requestSingle"
        ? "Identity request"
        : "Custom role request";
    },
    isMyRequest() {
      return this.user.master_id === this.request.last_modifier_master_id;
    },
    isPending() {
      return this.$route.name === "requestSingle"
        ? this.request.data.record_status.toLowerCase() === "pending"
        : this.request.data.requested_custom_role.approval_status.toLowerCase() ===
            "pending";
    },
    editable() {
      return !this.isMyRequest && this.isPending;
    }
  },
  methods: {
    getRequest() {
      console.log("path: " + this.$route.path);
      axios.get(this.$route.path).then(resp => {
        if (resp.data.data) {
          this.request = resp.data.data;
        } else {
          this.notFound = true;
        }
      });
    },
    handleApprove(req) {
      console.log("handle approve");
      axios
        .post("/identity/accept", {
          requests: [
            {
              master_id: req.data.master_id,
              version_no: req.data.version_no
            }
          ]
        })
        .then(response => {
          console.log(response.data);
          this.getRequest();
          this.$bvToast.toast("Identity request has been approved", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    handleReject(req, comment) {
      console.log("handle reject, comment: " + comment);
      axios
        .post("/identity/reject", {
          requests: [
            {
              master_id: req.data.master_id,
              version_no: req.data.version_no,
              comment: comment
            }
          ]
        })
        .then(response => {
          console.log(response.data);
          this.getRequest();
          if (response.data.success) {
            this.$bvToast.toast(
              "Incoming request has been successfully rejected",
              {
                title: "Success",
                autoHideDelay: 5000,
                variant: "success",
                solid: true
              }
            );
          } else {
            Object.keys(response.data.errors).forEach(key => {
              response.data.errors[key].forEach(err => {
                this.$bvToast.toast(err, {
                  title: "Error",
                  autoHideDelay: 6000,
                  variant: "danger",
                  solid: true
                });
              });
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  components: {
    IdentityCard
  }
};
</script>