/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import "babel-polyfill";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import { store } from "./_store";
import App from "./views/App";
import { router } from "./_helpers/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faUser,
  faSave,
  faInfoCircle,
  faSignInAlt,
  faForward,
  faCalendarAlt,
  faPaperPlane,
  faChevronLeft,
  faCheck,
  faTimes,
  faDownload,
  faRedo,
  faBan,
  faStar,
  faEdit,
  faQuestionCircle,
  faArrowRight,
  faArrowLeft,
  faUserEdit,
  faExclamationTriangle,
  faDiceD6
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

library.add(
  faRedo,
  faBan,
  faStar,
  faEdit,
  faSearch,
  faQuestionCircle,
  faUser,
  faSave,
  faInfoCircle,
  faSignInAlt,
  faForward,
  faCalendarAlt,
  faPaperPlane,
  faChevronLeft,
  faCheck,
  faTimes,
  faDownload,
  faArrowRight,
  faArrowLeft,
  faUserEdit,
  faExclamationTriangle,
  faDiceD6
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);

const app = new Vue({
  el: "#app",
  components: { App },
  router,
  store
});
