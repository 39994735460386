<template>
  <b-container>
    <h2 class="mb-4">Outgoing requests</h2>
    <p>{{ title }}</p>
    <b-tabs lazy v-model="tabIndex">
      <b-tab title="Pending" @click="tabIndex=0" :active="tabIndex===0">
        <outgoing-request-tab tab="pending"></outgoing-request-tab>
      </b-tab>
      <b-tab title="Approved" @click="tabIndex=1" :active="tabIndex===1">
        <outgoing-request-tab tab="approved"></outgoing-request-tab>
      </b-tab>
      <b-tab title="Rejected" @click="tabIndex=2" :active="tabIndex===2">
        <outgoing-request-tab tab="rejected"></outgoing-request-tab>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import OutgoingRequestTab from "../components/OutgoingRequestTab";

export default {
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    title() {
      switch (this.tabIndex) {
        case 0:
          return "The list below shows requests made by you that have not been reviewed yet.";
        case 1:
          return "The list below shows requests made by you that have been approved by others.";
        case 2:
          return "The list below shows requests made by you that have been rejected by others.";
      }
    }
  },
  components: {
    OutgoingRequestTab
  }
};
</script>