import Vue from "vue";
import Vuex from "vuex";

import { authentication } from "./authentication.module";
import { requestform } from "./requestform.module";
import { myrequests } from "./myrequests.module";
import { identities } from "./identities.module";
import { settings } from "./settings.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    authentication,
    myrequests,
    requestform,
    identities,
    settings
  }
});
