<template>
  <b-row class="justify-content-center">
    <b-col xl="4" lg="5" md="6" sm="12">
      <b-card border-variant="dark" align="left">
        <div class="d-flex justify-content-center">
          <img src="/img/logo_full.png" height="200" class="d-inline-block align-top" alt="Logo" />
        </div>

        <b-alert
          :show="expired"
          variant="warning"
          dismissible
        >Your session expired, please log in again.</b-alert>

        <b-alert
          :show="showUnauthorized && !loginClicked"
          variant="danger"
        >Username or password incorrect.</b-alert>

        <form @submit.prevent="handleSubmit">
          <b-form-group label="Username" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              v-model="username"
              trim
              autofocus
              :state="$v.username.$dirty ? !$v.username.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="org_cc">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="fieldset-2" description label="Password">
            <b-form-input
              id="input-branch"
              type="password"
              v-model="password"
              :state="$v.password.$dirty ? !$v.password.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="org_cc">This field is required</b-form-invalid-feedback>
          </b-form-group>

          <b-row class="mt-5 align-items-end d-flex">
            <b-col md="6">
              <strong class="text-secondary align">IDM</strong>
            </b-col>
            <b-col md="6">
              <b-button
                variant="primary"
                type="submit"
                class="float-right"
                :disabled="loginClicked"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-2"></font-awesome-icon>Sign In
                <b-spinner small v-if="loginClicked"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { authenticationService } from "../_services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  props: {
    expired: Boolean
  },
  data() {
    return {
      username: "",
      password: "",
      loginClicked: false
    };
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  created() {
    // reset login status
    //this.$store.dispatch("authentication/logout");

    // redirect to home if already logged in
    if (authenticationService.currentUser) {
      return this.$router.push("/");
    }
  },
  computed: {
    ...mapState("authentication", ["status"]),

    showUnauthorized() {
      return this.status.unauth;
    }
  },
  methods: {
    handleSubmit(e) {
      // check required fields
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      this.$v.$reset();

      this.loginClicked = true;
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password }).then(asd => {
          this.loginClicked = false;
          this.username = "";
          this.password = "";
        });
      }
    }
  }
};
</script>

