import { authHeader } from "../_helpers";
import { Role } from "../_helpers";
import { router } from "../_helpers/router";

export const authenticationService = {
  login,
  logout,
  getToken,
  getCurrentUserData,
  isAdmin,
  isHR,
  isManager,
  getRoles,
  getTokenData
};

function getToken() {
  return JSON.parse(localStorage.getItem("token"));
}

function isAdmin() {
  const data = getTokenData();
  return data && data.is_admin;
}

function isHR() {
  const data = getTokenData();
  return data && data.is_hr;
}

function isManager() {
  const data = getTokenData();
  return data && data.is_manager;
}

function isLegal() {
  const data = getTokenData();
  return data && data.is_legal;
}

function getTokenData() {
  const user = JSON.parse(localStorage.getItem("token"));
  return user && JSON.parse(atob(user.access_token.split(".")[1]));
}

// returns the roles of the authenticated user
function getRoles() {
  const roles = [];
  if (isHR()) {
    roles.push(Role.HR);
  }
  if (isManager()) {
    roles.push(Role.MANAGER);
  }
  if (isAdmin()) {
    roles.push(Role.ADMIN);
  }
  if (isLegal()) {
    roles.push(Role.LEGAL);
  }
  return roles;
}

// sends the login request and saves the token to local storage
function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ login_name: username, password: password })
  };
  console.log("authservice login");
  return fetch(`/api/auth/login`, requestOptions)
    .then(handleResponse)
    .then(token => {
      // login successful if there's a jwt token in the response
      if (token.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("token", JSON.stringify(token));
      }

      return token;
    });
}

// sends logout request and removes the token from local storage
function logout() {
  axios
    .post("/auth/logout", {})
    .then(response => {
      // remove user from local storage to log user out
      console.log("sending logout request...");
    })
    .catch(function(error) {
      // handle error
      console.log("error sending logout request: " + error);
    });
  localStorage.removeItem("token");
}

function handleResponse(response) {
  console.log("response: " + JSON.stringify(response));
  return response.text().then(text => {
    console.log("text: " + text);
    const data = text && JSON.parse(text);
    if (!response.ok) {
      console.log("!response.ok");
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        logout();
      }

      const error = (data && data.errors) || response.statusText;
      return Promise.reject(error);
    }

    return data.data;
  });
}

function getCurrentUserData() {
  return axios.get("/auth/me").then(response => {
    if (!response.ok) {
      // TODO reject promise
    }
    return response.data.data;
  });
}
