<template>
  <div>
    <b-row class="py-4">
      <b-col lg="5" cols="9" class="my-2">
        <b-pagination class="my-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </b-col>

      <b-col lg="1" cols="3" class="pl-lg-0 my-auto">
        <b-form-select size="sm" v-model="perPage" :options="perPageOptions"></b-form-select>
      </b-col>

      <b-col lg="6" class="my-lg-auto my-1">
        <filter-input v-model="filter" class="w-100" size="sm"></filter-input>
      </b-col>
    </b-row>

    <div v-if="loading" class="d-flex justify-content-center align-items-center my-4">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>

    <div v-else-if="visibleRequests.length">
      <identity-card
        v-for="req in visibleRequests"
        :key="`${req.request_type}${req.data.id}`"
        :id-request="req"
        :editable="false"
      ></identity-card>

      <b-pagination class="mt-4" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
    </div>

    <h5 class="text-danger" v-else-if="requests.length===0">You don't have any outgoing request</h5>

    <h5 class="text-danger" v-else>No results found</h5>
  </div>
</template>

<script>
import IdentityCard from "./IdentityCard";
import FilterInput from "./Filter";
import { filterRequests } from "../_helpers/common";
export default {
  data() {
    return {
      requests: [],
      loading: true,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 20, 50],
      filter: ""
    };
  },
  props: {
    tab: "pending" | "rejected" | "approved"
  },
  computed: {
    rows() {
      return this.filteredRequests.length;
    },
    visibleRequests() {
      return this.filteredRequests.slice(
        (this.currentPage - 1) * this.perPage,
        (this.currentPage - 1) * this.perPage + this.perPage
      );
    },
    filteredRequests() {
      return filterRequests(this.filter, this.requests);
    }
  },
  created() {
    console.log(this.tab + " created");
    if (this.$parent.active) {
      this.getRequests();
    }
  },

  methods: {
    getRequests() {
      axios.get(`/identity/requests/outgoing/${this.tab}`).then(response => {
        this.requests = response.data.data;
        this.loading = false;
        console.log(this.requests);
      });
    },
    handleFilter(val) {
      console.log(val);
    }
  },
  components: {
    IdentityCard,
    FilterInput
  }
};
</script>