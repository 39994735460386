<template>
  <b-container>
    <h2 class="mb-4">Admin page</h2>
    <warning-text></warning-text>
    <b-dropdown variant="primary" text="Select " class="mb-5">
      <b-dropdown-item :to="{name: 'primary' }">Primary roles</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'custom' }">Custom roles</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'tim' }">TIM templates</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'nonAdTemplates' }">Non-AD templates</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'access' }">Access levels</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'ea' }">EA entities</b-dropdown-item>
      <b-dropdown-item v-if="admin" :to="{name: 'external' }">External companies</b-dropdown-item>
      <b-dropdown-item :to="{name: 'departments'}">Departments</b-dropdown-item>
      <b-dropdown-item :to="{name: 'orgunits'}">Organizational units</b-dropdown-item>
    </b-dropdown>
  </b-container>
</template>
<script>
import WarningText from "../components/WarningText";
import { authenticationService } from "../_services";
export default {
  computed: {
    admin() {
      return authenticationService.isAdmin();
    }
  },
  components: {
    WarningText
  }
};
</script>

