import Vue from "vue";
import VueRouter from "vue-router";
import Reports from "../views/Reports";
import Profile from "../views/Profile";
import NewIdentity from "../views/IdentityNew";
import Home from "../views/Home";
import IncomingIdentity from "../views/IncomingRequests";
import OutgoingIdentity from "../views/OutgoingRequests";
import PreviewIdentity from "../views/IdentityPreview";
import Settings from "../views/Settings";
import Login from "../views/Login";
import Admin from "../views/Admin";
import { Role } from "./role";
import { intersect } from "./common";
import { authenticationService } from "../_services";
import { store } from "../_store";
import PrimaryRole from "../components/admin/PrimaryRole";
import AccessLevel from "../components/admin/AccessLevel";
import OrgUnit from "../components/admin/OrgUnit";
import TimTemplates from "../components/admin/TimTemplates";
import CustomRole from "../components/admin/CustomRole";
import Company from "../components/admin/Company";
import Departments from "../components/admin/Departments";
import ExternalCompany from "../components/admin/ExternalCompany";
import RequestSingle from "../views/RequestSingle";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/identity/new",
      name: "newIdentity",
      component: NewIdentity,
      meta: { authorize: [Role.MANAGER, Role.HR] }
    },
    {
      path: "/identity/edit/:id/:master_id/:version_no",
      name: "editIdentity",
      component: NewIdentity
    },
    {
      path: "/identity/new/preview",
      name: "newIdentityPreview",
      component: PreviewIdentity
    },
    {
      path: "/identity/incoming",
      name: "incomingIdentity",
      component: IncomingIdentity
    },
    {
      path: "/identity/outgoing",
      name: "outgoingIdentity",
      component: OutgoingIdentity
    },
    {
      path: "/reports",
      name: "reports",
      component: Reports
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      meta: { authorize: [Role.ADMIN, Role.HR] }
    },
    {
      path: "/admin/primary",
      name: "primary",
      component: PrimaryRole,
      meta: { authorize: [Role.ADMIN, Role.HR] }
    },
    {
      path: "/admin/custom",
      name: "custom",
      component: CustomRole,
      meta: { authorize: [Role.ADMIN] }
    },
    {
      path: "/admin/tim",
      name: "tim",
      component: TimTemplates,
      meta: { authorize: [Role.ADMIN] },
      props: {isNonAd: false}
    },
    {
      path: "/admin/nonAdTemplates",
      name: "nonAdTemplates",
      component: TimTemplates,
      meta: { authorize: [Role.ADMIN] },
      props: {isNonAd: true}
    },
    {
      path: "/admin/access",
      name: "access",
      component: AccessLevel,
      meta: { authorize: [Role.ADMIN] }
    },
    {
      path: "/admin/ea",
      name: "ea",
      component: Company,
      meta: { authorize: [Role.ADMIN] }
    },
    {
      path: "/admin/external",
      name: "external",
      component: ExternalCompany,
      meta: { authorize: [Role.ADMIN] }
    },
    {
      path: "/departments",
      name: "departments",
      component: Departments,
      meta: { authorize: [Role.ADMIN, Role.HR] }
    },
    {
      path: "/orgunits",
      name: "orgunits",
      component: OrgUnit,
      meta: { authorize: [Role.ADMIN, Role.HR] }
    },
    {
      path: "/identity/request/:id",
      name: "requestSingle",
      component: RequestSingle
    },
    {
      path: "/identity/custom_role_request/:id",
      name: "customRoleRequestSingle",
      component: RequestSingle
    },

    // otherwise redirect to home
    { path: "*", redirect: "/" }
  ]
});

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const token = authenticationService.getToken();
  const tokenStillValid = token && new Date() < new Date(token.expires_at);

  if (to.name === "login" && from.name === "login") {
    console.log("asd");
    return;
  }

  if (!token && to.name !== "login") {
    console.log("!token");
    // not logged in so redirect to login page
    return next({ name: "login" });
  }

  // check if already logged in when accessing login page
  if (tokenStillValid && to.name === "login") {
    console.log("token valid, no need to log in");
    return next({ name: "home" });
  }

  // if token expired redirect to login and clear local storage
  if (token && !tokenStillValid) {
    console.log("token expired, logging out...");
    store.dispatch("authentication/logout");
    next({ name: "login", params: { expired: true } });
  }

  // console.log("authorized to: " + authorize);
  // console.log("roles: " + authenticationService.getRoles());

  // check if route is authorized for the user
  if (authorize && intersect(authorize, authenticationService.getRoles()).length === 0) {
    // user is not authorized to view this route, redirect to home page
    console.log("route not authorized");
    return next({ name: "home" });
  }

  next();
});
