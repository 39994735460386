import { formatDate } from "./common";

export const IdentityRequestAttributes = [
  { name: "Version No.", key: "version_no", changeable: false },
  { name: "Username", key: "username", changeable: false },
  { name: "Email", key: "email" },
  { name: "Full name", key: "full_name" },
  { name: "Title", key: "title" },
  { name: "First name", key: "first_name" },
  { name: "Last name", key: "last_name" },
  { name: "Tax ID", key: "tax_id" },
  { name: "Company", key: "company_name" },
  { name: "External company", key: "external_company_name" },
  {
    name: "Start date",
    key: "start_date",
    printer: (str, options) => {
      //return formatDate(str, options.format);
      return str;
    }
  },
  {
    name: "End date",
    key: "end_date",
    printer: (str, options) => {
      //return formatDate(str, options.format);
      return str;
    }
  },
  {
    name: "Last workday",
    key: "last_workday",
    printer: (str, options) => {
      //return formatDate(str, options.format);
      return str;
    }
  },
  { name: "User status", key: "user_status" },
  { name: "Employment type", key: "employment_type" },
  {
    name: "Direct manager name",
    key: "direct_manager_full_name"
  },
  { name: "Unique rights", key: "unique_rights_request" },
  { name: "Primary role", key: "primary_role_name" },
  { name: "Organizational unit", key: "organizational_unit_name" },
  { name: "Department", key: "department_name" },
  {
    name: "Predefined custom roles",
    key: "custom_roles",
    printer: (roles, options) => {
      return roles
        ? "<ul>" +
            roles
              .map(r => {
                return "<li>" + r.name + "</li>";
              })
              .join("") +
            "</ul>"
        : "-";
    },
    listItemPrinter: item => item.name,
    type: "list"
  },
  { name: "Access level", key: "access_level_name" }
];

const previewAttributeKeys = [
  "first_name",
  "last_name",
  "end_date",
  "start_date",
  "last_workday",
  "title",
  "employment_type",
  "email",
  "company_name",
  "external_company_name",
  "tax_id",
  "custom_roles"
];

export const previewAttributes = IdentityRequestAttributes.filter(attr =>
  previewAttributeKeys.includes(attr.key)
);

export const RequestType = {
  MODIFICATION: "modification",
  CREATION: "identity",
  DEACTIVATION: "deactivation",
  REACTIVATION: "reactivation",
  LEAVE: "leave",
  CUSTOM_ROLE: "custom_role_request",
  EXT_CO: "external_company_request"
};

// admin page forms
export const FormSelect = {
  PRIMARY: "primary",
  CUSTOM: "custom",
  ACCESS: "access",
  COMPANY: "company",
  TIM: "tim",
  DEPARTMENT: "department",
  ORG_UNIT: "orgunit"
};
