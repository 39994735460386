<template>
  <main>
    <b-row class="flex-wrap-reverse">
      <b-col lg="8">
        <main-panel />
      </b-col>
      <b-col lg="4">
        <search-panel />
      </b-col>
    </b-row>
  </main>
</template>

<script>
import SearchPanel from "../components/Searchpanel";
import MainPanel from "../components/MainPanel";

export default {
  data() {
    return {
      loading: false,
      users: null,
      error: null
    };
  },
  components: {
    SearchPanel,
    MainPanel
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>