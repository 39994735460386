<template>
  <b-card title="Existing Identities">
    <b-row class="mt-4 mb-2">
      <b-col sm="6" md="8" lg="5">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" limit="4"></b-pagination>
      </b-col>

      <b-col sm="6" md="4" lg="3">
        <b-form-group
          label="Show:"
          label-cols-sm="6"
          label-align-sm="right"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select v-model="perPage" id="perPageSelect" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="12" lg="4" class="float-right">
        <b-input-group>
          <b-form-input v-model="filter" type="search" placeholder="Quick search"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row v-if="search" class="my-2 align-items-center">
      <b-col sm="6">
        <h5 class="my-0" :class="resultsCnt > 0 ? 'text-success':'text-danger'">{{ resultsMsg }}</h5>
      </b-col>
      <b-col sm="6">
        <export-button
          :data="searchResults"
          btnClass="float-right"
          size="sm"
          btnVariant="outline-primary"
          label="Export results"
          type="xlsx"
        ></export-button>
      </b-col>
    </b-row>

    <b-table
      responsive
      hover
      small
      foot-clone
      :busy="loading"
      :items="searchResults"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template slot="cell(start_date)" slot-scope="row">{{ formatDate(row.value) }}</template>

      <template slot="cell(end_date)" slot-scope="row">{{ formatDate(row.value) }}</template>

      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" class="mb-1" @click="row.toggleDetails">Details</b-button>
        <b-button
          size="sm"
          class="mb-1"
          @click="handleEdit(row.item)"
          :disabled="row.item.user_status === 'Inactive' || row.item.user_status === 'Leave'"
          v-if="row.item.editable"
        >Edit</b-button>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <user-details
            :masterId="row.item.master_id"
            showHistory
            showTitle
            :editable="row.item.editable"
          ></user-details>
        </b-card>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" limit="4"></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { IdentityRequestAttributes } from "../_helpers/models";
import { formatDate } from "../_helpers";
import ExportButton from "./ExportButton";
import UserDetails from "./UserDetails";
import { authenticationService } from "../_services";
export default {
  data() {
    return {
      fields: [
        {
          key: "full_name",
          label: "Name",
          sortable: true
        },
        {
          key: "department_name",
          sortable: true,
          label: "Department"
        },
        {
          key: "start_date",
          sortable: true
        },
        {
          key: "end_date",
          sortable: true
        },
        {
          key: "direct_manager_full_name",
          label: "Manager",
          sortable: true
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 20, 50],
      filter: null,
      filteredRowsCnt: 0,

      idAttributes: IdentityRequestAttributes
    };
  },
  computed: {
    ...mapState("identities", ["search", "mainTableItems", "searchResults", "loading"]),
    ...mapState("settings", ["dateFormat"]),
    resultsCnt() {
      return this.filter ? this.filteredRowsCnt : this.searchResults.length;
    },
    resultsMsg() {
      const len = this.resultsCnt;
      return len > 1
        ? `${len} results found`
        : len === 1
        ? `${len} result found`
        : "No results found";
    },
    totalRows: {
      get() {
        return this.filter ? this.filteredRowsCnt : this.searchResults.length;
      },
      set(value) {
        this.filteredRowsCnt = value;
      }
    },
    searchResults: {
      get() {
        return !this.search
          ? this.mainTableItems
          : this.$store.state.identities.searchResults;
      }
    }
  },
  created() {
    this.getData();
  },

  methods: {
    ...mapActions("identities", ["stopLoading", "startLoading"]),
    formatDate(dateStr) {
      return formatDate(dateStr, this.dateFormat);
    },
    getData() {
      if (this.$store.state.identities.mainTableItems.length === 0) {
        axios.get(`/identity/live/active`).then(response => {
          this.stopLoading();
          this.$store.dispatch("identities/addIdentities", response.data.data);
        });
      } else {
        this.stopLoading();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEdit(item) {
      this.$store.commit("requestform/RESET_FORM_PROGRESS");
      console.log("item:", item);
      this.$router.push({
        name: "editIdentity",
        params: {
          master_id: item.master_id,
          version_no: item.version_no,
          id: item.id
        }
      });
    }
  },
  components: {
    ExportButton,
    UserDetails
  }
};
</script>
