<template>
  <div class="d-flex align-items-center">
    <span :class="timClass">{{ `${tim.name} (${tim.profile})` }}</span>
    <span v-b-tooltip.hover :title="tim.description" class="ml-1 d-flex align-items-center">
      <font-awesome-icon class="text-secondary" icon="question-circle"></font-awesome-icon>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    tim: Object,
    timClass: String | Array
  }
};
</script>