<template>
  <b-container fluid>
    <h2>{{templateName}} editor</h2>
    <warning-text></warning-text>
    <export-button
      btnVariant="primary"
      btnClass="mr-2"
      :data="filteredData"
      type="xlsx"
      label="Export (xlsx)"
      :isTimTemplate="true"
    ></export-button>
    <h5 class="my-4">{{ formTitle }}</h5>
    <b-form @submit="showConfirmModal" @reset="resetForm" v-if="show">
      <b-row>
        <b-col xl="5" lg="6" sm="12">
          <b-form-group label="Template name*">
            <b-form-input
              id="tim_name"
              v-model="tim_name"
              :state="$v.tim_name.$dirty ? !$v.tim_name.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Profile (ID)*">
            <b-form-input
              id="tim_prof"
              v-model="tim_profile"
              :state="$v.tim_profile.$dirty ? !$v.tim_profile.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Description*">
            <b-form-textarea
              id="tim_desc"
              v-model="tim_desc"
              placeholder="Short description of the template..."
              :state="$v.tim_desc.$dirty ? !$v.tim_desc.$error : null"
            ></b-form-textarea>
            <b-form-invalid-feedback id="acc_name">This field is required</b-form-invalid-feedback>
          </b-form-group>
          <form-buttons></form-buttons>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-5 mb-3">
      <b-col md="6">
        <h5>Existing {{templateName}}</h5>
      </b-col>
      <b-col md="6">
        <filter-input v-model="filter" size="sm"></filter-input>
      </b-col>
    </b-row>

    <b-modal id="adminconfirm" ref="modal" title="Confirmation" @ok="submitForm">
      <p class="my-2">{{ modalText }}</p>
    </b-modal>

    <b-table
      sticky-header="500px"
      hover
      small
      filterable
      :busy="loading"
      :items="tableData"
      :fields="tableFields"
      :filter="filter"
      sort-by="name"
    >
      <template slot="cell(action)" slot-scope="row">
        <b-button size="sm" @click="handleEdit(row.item)">Edit</b-button>
      </template>

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-table>
  </b-container>
</template>
<script>
import ExportButton from "../ExportButton";
import FormButtons from "../FormButtons";
import { deCapitalize } from "../../_helpers/common";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FilterInput from "../Filter";
import WarningText from "../WarningText";
export default {
  created() {
    this.isNonAd ? this.getNonADTemplates() : this.getTimTemplates();
  },
  data() {
    return {
      show: true,

      tim_name: "",
      tim_profile: "",
      tim_desc: "",

      tableData: [],
      tableFields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "profile",
          sortable: true
        },
        {
          key: "description",
          sortable: true
        },
        {
          key: "action",
          sortable: false,
          label: ""
        }
      ],

      listTemplates: [],

      loading: true,
      defaultOption: { value: "", text: "Select option" },
      filter: "",
      editId: null
    };
  },
  props: {
    isNonAd: Boolean
  },
  validations: {
    tim_name: {
      required
    },
    tim_profile: {
      required
    },
    tim_desc: {
      required
    }
  },
  computed: {
    postData() {
      return {
        name: this.tim_name,
        profile: this.tim_profile,
        description: this.tim_desc,
        is_non_ad: this.isNonAd
      };
    },
    postUrl() {
      return this.editId ? `/tim_template/edit/${this.editId}` : "/tim_template/create";
    },
    successMsg() {
      return this.editId ? `${this.templateName} modification OK` : `New ${this.templateName} added`;
    },
    formTitle() {
      return this.editId ? `Edit ${this.templateName}` : `New ${this.templateName}`;
    },
    modalText() {
      return `Confirm ${deCapitalize(this.formTitle)}?`;
    },

    timOptions() {
      return this.listTemplates.map(t => ({ name: t.name, id: t.id }));
    },
    filteredData() {
      return this.tableData.filter(t =>
          t.name.toLowerCase().includes(this.filter.toLowerCase()) ||
          t.profile.toLowerCase().includes(this.filter.toLowerCase()) ||
          t.description.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    dataOwnerOptions() {
      const options = this.listDataOwners.map(owner => ({
        value: owner.id,
        text: `${owner.full_name} (${owner.username})`
      }));
      return [this.defaultOption, ...options];
    },
    templateName() {
      return this.isNonAd ? 'Non-AD templates' : 'TIM templates';
    }
  },
  methods: {
    showConfirmModal(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$bvModal.show("adminconfirm");
    },
    submitForm() {
      axios
        .post(this.postUrl, this.postData)
        .then(response => {
          this.$bvToast.toast(this.successMsg, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true
          });
          this.resetForm();
          this.isNonAd ? this.getNonADTemplates() : this.getTimTemplates();
        })
        .catch(error => {
          console.log(error.response);
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            autoHideDelay: 6000,
            variant: "danger",
            solid: true
          });
        });
    },

    resetForm() {
      this.$v.$reset();
      this.tim_name = "";
      this.tim_profile = "";
      this.tim_desc = "";

      this.filter = "";
      this.editId = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getTimTemplates() {
      this.loading = true;

      axios.get("/tim_template/list/tim").then(response => {
        this.listTemplates = response.data.data;

        this.tableData = this.listTemplates;
        this.loading = false;
      });
    },

    getNonADTemplates() {
        this.loading = true;

        axios.get("/tim_template/list/non_ad").then(response => {
            this.listTemplates = response.data.data;

            this.tableData = this.listTemplates;
            this.loading = false;
        });
    },
    
    handleEdit(row) {
      this.editId = row.id;
      window.scrollTo(0, 0);

      this.tim_name = row.name;
      this.tim_profile = row.profile;
      this.tim_desc = row.description;
    }
  },
  components: {
    ExportButton,
    FormButtons,
    FilterInput,
    WarningText
  }
};
</script>
