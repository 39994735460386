<template>
  <div class="mt-2 mycard">
    <div class="d-flex mb-3 justify-content-between">
      <div class="d-flex align-items-center">
        <div :class="`requesttype-iconholder bg-${this.cardData.iconColor}`">
          <font-awesome-icon
            :icon="cardData.icon"
            :class="`text-white ${this.cardData.iconClass || ''}`"
          ></font-awesome-icon>
        </div>

        <p class="card-text ml-3">
          <strong>#{{this.idRequest.data.id}} {{ this.cardData.title }}</strong>
          <b-badge :variant="recordStatusColor" title="Status">{{ recordStatus }}</b-badge>
        </p>
      </div>

      <span>
        <font-awesome-icon icon="calendar-alt" :style="{ color: 'dimgrey' }"></font-awesome-icon>
        {{ formatDate(this.idRequest.data.created_at) }}
      </span>
    </div>

    <p>Request made by: {{ this.requester }}</p>
    <div class="d-flex justify-content-between align-items-center">
      <b-button
        v-b-toggle="this.idRequest.data.id.toString()"
        @click="showDetails=true"
        size="sm"
      >Details</b-button>
      <div v-if="editable" class="d-flex align-items-center ml-auto">
        <b-form-checkbox :checked="selected" @change="handleSelect" name="checkbox-1"></b-form-checkbox>
        <b-button
          class="ml-2"
          variant="outline-danger"
          size="sm"
          v-b-modal="`reject-${idRequest.request_type}-${idRequest.data.id}`"
        >
          <font-awesome-icon icon="times" class="mr-2"></font-awesome-icon>Reject
        </b-button>
        <b-button
          class="ml-2"
          variant="outline-success"
          size="sm"
          v-b-modal="`approve-${idRequest.request_type}-${idRequest.data.id}`"
        >
          <font-awesome-icon icon="check" class="mr-2"></font-awesome-icon>Approve
        </b-button>
      </div>
    </div>

    <b-collapse :id="this.idRequest.data.id.toString()" class="mt-2">
      <b-card v-if="this.showDetails">
        <external-company-details v-if="this.idRequest.request_type === 'external_company_request'" :details="idRequest"></external-company-details>
        <user-details v-else :details="idRequest"></user-details>
      </b-card>
    </b-collapse>

    <confirm-modal
      :modalid="`approve-${idRequest.request_type}-${idRequest.data.id}`"
      title="Confirmation"
      :req="idRequest"
      @confirm="handleApprove"
      :text="cardData.modalText"
    ></confirm-modal>
    <confirm-modal
      :modalid="`reject-${idRequest.request_type}-${idRequest.data.id}`"
      title="Rejection"
      :req="idRequest"
      reject-modal
      :text="cardData.modalText"
      @confirm="handleReject"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "./ConfirmModal";
import IdentityCardBodyModification from "./IdentityCardBodyModification";
import IdentityCardBodyNew from "./IdentityCardBodyNew";
import { mapState, mapGetters } from "vuex";
import { formatDate, RequestType } from "../_helpers";
import UserDetails from "./UserDetails";
import ExternalCompanyDetails from "./ExternalCompanyDetails";
export default {
  props: {
    idRequest: Object,
    editable: Boolean,
    selected: Boolean
  },
  data() {
    return {
      showDetails: false
    };
  },
  computed: {
    ...mapState("myrequests", ["selectedIncomingRequests", "selectedCustomRequests"]),
    ...mapGetters("settings", ["dateTimeFormat"]),
    cardData() {
      let data = {};
      switch (this.idRequest.request_type) {
        case RequestType.CREATION:
          data = {
            title: "New identity",
            icon: "star",
            iconColor: "warning",
            modalText: `Identity creation for user ${this.idRequest.data.full_name}`
          };
          break;
        case RequestType.MODIFICATION:
          data = {
            title: "Identity modification",
            icon: "user-edit",
            iconColor: "primary",
            iconClass: "requesttype-icon--modify",
            modalText: `Identity modification of user ${this.idRequest.data.full_name
              .value ||
              this.idRequest.data.full_name.old ||
              this.idRequest.data.full_name} (${this.idRequest.data.username})`
          };
          break;
        case RequestType.DEACTIVATION:
          data = {
            title: "Identity deactivation",
            icon: "ban",
            iconColor: "danger",
            modalText: `Identity deactivation of user ${this.idRequest.data.full_name.value} (${this.idRequest.data.username})`
          };
          break;
        case RequestType.REACTIVATION:
          data = {
            title: "Identity reactivation",
            icon: "redo",
            iconColor: "success",
            modalText: `Identity reactivation of user ${this.idRequest.data.full_name.value} (${this.idRequest.data.username})`
          };
          break;
        case RequestType.LEAVE:
          data = {
            title: "Leave request",
            icon: "ban",
            iconColor: "dark",
            modalText: `Request to put user ${this.idRequest.data.full_name.value} (${this.idRequest.data.username}) on leave`
          };
          break;
        case RequestType.CUSTOM_ROLE:
          data = {
            title: "Custom role request",
            icon: "dice-d6",
            iconColor: "dark",
            modalText: `Custom role request ${this.idRequest.data.requested_custom_role.name}`
          };
          break;
        case RequestType.EXT_CO:
          data = {
            title: "External company request",
            icon: "dice-d6",
            iconColor: "dark",
            modalText: `External company request ${this.idRequest.data.name}`
          };
          break;
      }
      return data;
    },
    requester() {
      return this.idRequest.data.last_modifier_full_name || "?";
    },
    recordStatus() {
      return this.idRequest.request_type === RequestType.CUSTOM_ROLE
        ? this.idRequest.data.requested_custom_role.approval_status
        : this.idRequest.data.record_status;
    },
    recordStatusColor() {
      switch (this.recordStatus.toLowerCase()) {
        case "rejected":
          return "danger";
        case "pending":
          return "primary";
        case "live":
          return "success";
        case "approved":
          return "info";
        default:
          return "secondary";
      }
    }
  },
  methods: {
    formatDate(dateStr) {
      return formatDate(dateStr, this.dateTimeFormat);
    },
    handleSelect() {
      if (this.idRequest.request_type === RequestType.CUSTOM_ROLE) {
        this.selectedCustomRequests.find(r => r.data.id === this.idRequest.data.id)
          ? this.$store.commit(
              "myrequests/REMOVE_CUSTOM_REQUEST_FROM_SELECTED",
              this.idRequest
            )
          : this.$store.commit(
              "myrequests/ADD_CUSTOM_REQUEST_TO_SELECTED",
              this.idRequest
            );
      } else {
        this.selectedIncomingRequests.find(r => r.data.id === this.idRequest.data.id)
          ? this.$store.commit("myrequests/REMOVE_REQUEST_FROM_SELECTED", this.idRequest)
          : this.$store.commit("myrequests/ADD_REQUEST_TO_SELECTED", this.idRequest);
      }
    },
    handleApprove() {
      if (this.idRequest.request_type === RequestType.CUSTOM_ROLE) {
        this.$emit("approveCustom", this.idRequest);
      } else if (this.idRequest.request_type === RequestType.EXT_CO){
        this.$emit("approveExtCo", this.idRequest);
      } else {
        this.$emit("approve", this.idRequest);
      }
    },
    handleReject(comment) {
      if (this.idRequest.request_type === RequestType.CUSTOM_ROLE) {
        this.$emit("rejectCustom", this.idRequest, comment);
      } else if (this.idRequest.request_type === RequestType.EXT_CO){
          this.$emit("rejectExtCo", this.idRequest, comment);
      } else {
        this.$emit("reject", this.idRequest, comment);
      }
    }
  },
  components: {
      ExternalCompanyDetails,
    IdentityCardBodyModification,
    IdentityCardBodyNew,
    ConfirmModal,
    UserDetails
  }
};
</script>

<style lang="scss">
.mycard {
  //  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  background-color: #f7f9fc;
  padding: 1.25rem;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
  margin-bottom: 20px;
  border: 1px solid #e9ebee;
  transition: all 0.2s;
}
.mycard:hover {
  box-shadow: 0 10px 10px 0 rgba(10, 31, 68, 0.1), 0 0 5px 2px rgba(10, 31, 68, 0.1);
}
.requesttype-iconholder {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
.requesttype-icon--modify {
  position: absolute;
  left: 12px;
}
</style>
