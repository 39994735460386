
<template>
  <b-modal
    :id="modalid"
    ref="modal"
    :title="title"
    @ok="handleOk"
    @show="resetModal"
    @hidden="resetModal"
    :header-text-variant="rejectModal ? 'danger' : 'dark'"
  >
    <p class="my-2">{{ modalText }}</p>

    <form v-if="rejectModal" ref="form" class="mt-4">
      <b-form-group
        label="Comment"
        label-for="comment-input"
        invalid-feedback="Must be at least 20 characters"
        :state="isCommentValid"
      >
        <b-form-input id="comment-input" v-model="rejectComment" :state="isCommentValid"></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    rejectModal: Boolean,
    title: String,
    text: String,
    req: {
      default: null,
      type: Object
    },
    modalid: String,
    bulk: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState("myrequests", [
      "selectedIncomingRequests",
      "selectedCustomRequests",
      "rejectComment"
    ]),
    isCommentValid: {
      get() {
        return this.rejectComment.length > 0
          ? this.rejectComment.length >= 20
          : null;
      },
      set(value) {
        this.value = value;
      }
    },
    rejectComment: {
      get() {
        return this.$store.getters["myrequests/getRejectComment"];
      },
      set(value) {
        this.$store.commit("myrequests/SET_REJECT_COMMENT", value);
      }
    },
    userName() {
      return !this.bulk ? this.req.data.username : "";
    },
    requestType() {
      return !this.bulk && this.req.request_type;
    },
    name() {
      const full = !this.bulk ? this.req.data.full_name : "";
      return this.requestType === "modification"
        ? full.changed
          ? full.new
          : full.value
        : full;
    },
    modalText() {
      const user = `${this.userName} (${this.name})`;
      return this.bulk ? this.modalTextBulk : this.text;
    },
    modalTextBulk() {
      return `${this.rejectModal ? "Reject" : "Approve"} all selected (${this
        .selectedIncomingRequests.length +
        this.selectedCustomRequests.length}) requests?`;
    }
  },
  methods: {
    checkFormValidity() {
      // const valid = this.$refs.form.checkValidity()
      // this.nameState = valid ? 'valid' : 'invalid'
      // return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Exit when the input isn't valid
      if (this.rejectModal && !this.isCommentValid) {
        return;
      }
      this.rejectModal
        ? this.$emit("confirm", this.rejectComment)
        : this.$emit("confirm");
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    resetModal() {
      this.rejectComment = "";
      this.isCommentValid = null;
    }
  }
};
</script>



