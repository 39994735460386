<template>
  <datepicker
    placeholder="Select date"
    :monday-first="mondayFirst"
    :highlighted="highlighted"
    :format="dateFormat"
    :disabled-dates="disabledDates"
    :calendar-class="calendarClass"
    bootstrap-styling
    :value="date"
    clear-button
    @input="handleInput"
    full-month-name
  >
    <template slot="afterDateInput">
      <span class="input-group-append">
        <span class="input-group-text">
          <font-awesome-icon icon="calendar-alt"></font-awesome-icon>
        </span>
      </span>
    </template>
  </datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { mapState } from "vuex";

export default {
  components: {
    Datepicker
  },
  computed: {
    ...mapState("settings", ["dateFormat", "mondayFirst"]),
    calendarClass() {
      return `dp-bottom-margin ${this.toggleLeft ? "toggle-left" : ""}`;
    },
    highlighted() {
      return { dates: [new Date()] };
    },
    disabledDates() {
      return !this.onlyFutureDates ? {} : { to: new Date(Date.now() - 864e5) }; // yesterday
    },
    date() {
      return this.value ? this.value : null;
    }
  },
  props: {
    onlyFutureDates: Boolean,
    toggleLeft: Boolean,
    value: String
  },
  methods: {
    handleInput(date) {
      console.log("datepicker handleInput");
      this.$emit("input", date ? date.toISOString() : "");
    }
  }
};
</script>
<style >
.dp-bottom-margin:after {
  position: absolute;
  content: "";
  bottom: -40px;
  height: 40px;
  width: 1px;
}
</style>