const getDefaultState = () => {
  return {
    // step 1 is the form, 2 is the preview
    step: 1,

    // modified identity
    item: {},

    // original identity
    itemOrig: {},

    // dropdown etc options on new identity request page
    identityCreationOptions: null,

    // the default dropdown option
    defaultSelectOption: {
      value: "",
      text: "Select option"
    },

    // controls whether or not the email field is editable
    emailTaken: false
  };
};

export const requestform = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    STEP_NEXT(state) {
      state.step++;
    },
    STEP_PREV(state) {
      state.step--;
    },
    SET_STEP(state, step) {
      state.step = step;
    },
    SET_FORM_OPTIONS(state, data) {
      state.identityCreationOptions = data;
    },
    SET_PREFILLED_ITEM(state, item) {
      state.item = item;
      state.itemOrig = item;
    },
    UPDATE_ITEM(state, { key, value }) {
      state.item = { ...state.item, [key]: value };
    },
    SET_EMAIL_TAKEN(state, bool) {
      state.emailTaken = bool;
    },
    RESET_FORM_PROGRESS(state) {
      state.step = 1;
      state.item = {};
      state.itemOrig = {};
      state.emailTaken = false;
      state.identityCreationOptions = null;
      // state = getDefaultState();
    }
  },
  actions: {
    stepNext: ({ commit }) => commit("STEP_NEXT"),
    stepPrev: ({ commit }) => commit("STEP_PREV"),
    resetFormProgress: ({ commit }) => commit("RESET_FORM_PROGRESS")
  },
  getters: {
    companyOptions: state => {
      return !state.identityCreationOptions
        ? []
        : [
            state.defaultSelectOption,
            ...state.identityCreationOptions.companies.map(c => ({
              value: c.id,
              text: c.name
            }))
          ];
    },

    locationOptions: state => {
      return !state.identityCreationOptions
        ? []
        : [
            state.defaultSelectOption,
            ...state.identityCreationOptions.locations.map(l => ({
              value: l.id,
              text: l.name
            }))
          ];
    },

    externalCompanyOptions: state => {
      return !state.identityCreationOptions
        ? []
        : [
            state.defaultSelectOption,
            ...state.identityCreationOptions.external_companies
              .map(c => ({
                value: c.id,
                text: c.name
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          ];
    },

    employmentTypeOptions: state => {
      return !state.identityCreationOptions
        ? []
        : [
            state.defaultSelectOption,
            ...state.identityCreationOptions.employment_types.map(emp => ({
              value: emp.id,
              text: emp.employment_type,
              contract_type: emp.contract_type
            }))
          ];
    },

    primaryRoleOptions: state => {
      return !state.identityCreationOptions
        ? []
        : [
            state.defaultSelectOption,
            ...state.identityCreationOptions.primary_roles
              .filter(role => role.company_id === state.item.company_id)
              .map(role => ({
                value: role.id,
                text: `${role.name} (OU: ${role.organizational_unit_name})`,
                role_name: role.name,
                access_levels: role.access_levels,
                org_unit_name: role.organizational_unit_name,
                org_unit_code: role.organizational_unit_code,
                department_name: role.department_name,
                department_code: role.department_code,
                manager_name: role.manager_full_name,
                manager_username: role.manager_username
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          ];
    },

    predefinedCustomRoleOptions: state => {
      return !state.identityCreationOptions
        ? []
        : state.identityCreationOptions.custom_roles;
    },

    accessLevelOptions: (state, getters) => {
      if (getters.primaryRoleOptions && getters.primaryRoleOptions.length > 0) {
        const found = getters.primaryRoleOptions.find(
          role => role.value === state.item.primary_role_id
        );
        //console.log("found primary role: " + found);
        if (found) {
          if (found.value === "") {
            return [found];
          }
          return [
            state.defaultSelectOption,
            ...found.access_levels
              .map(level => ({
                value: level.primary_role_access_level_id,
                text: level.name
              }))
              .sort((a, b) => a.text.localeCompare(b.text))
          ];
        }
      }
      return [state.defaultSelectOption];
    },

    statusOptions: state => {
      return !state.identityCreationOptions
        ? []
        : state.identityCreationOptions.user_statuses.map(status => ({
            value: status.id,
            text: status.user_status
          }));
    }
  }
};
