<template>
  <b-row>
    <b-col md="10" class="order-2 order-sm-1">
      <user-details-row attrName="Name" :attrValue="details.data.name"></user-details-row>
      <user-details-row attrName="Official Name" :attrValue="details.data.official_name"></user-details-row>
      <user-details-row attrName="Contract owner manager" :attrValue="details.data.manager_full_name"></user-details-row>
      <user-details-row attrName="Tax ID" :attrValue="details.data.tax_id"></user-details-row>
      <user-details-row attrName="Comment" :attrValue="details.data.comment"></user-details-row>
      <user-details-row attrName="Modified at" :attrValue="details.data.created_at" datetime></user-details-row>
      <user-details-row attrName="Hidden" :attrValue="details.data.hidden ? 'Yes' : 'No'"></user-details-row>
    </b-col>
  </b-row>
</template>

<script>
    import { formatDate } from "../_helpers";
    import { mapState } from "vuex";
    import UserDetailsRow from "./UserDetailsRow.vue";
    export default {
        name: 'ExternalCompanyDetails',
        created() {
            console.log(this.details);
        },
        props: {
            details: Object,
            masterId: Number,
            showComment: Boolean,
            showTitle: Boolean,
            editable: Boolean
        },
        data() {
            return {};
        },
        computed: {
            ...mapState("settings", ["dateFormat"]),
            loaded() {
                if (this.details) {
                    return true;
                }
                return this.loadedLatest && this.loadedHistory;
            },
        },
        methods: {
            formatDate(str) {
                return str ? formatDate(str, this.dateFormat) : "-";
            }
        },
        components: {
            UserDetailsRow
        }
    };
</script>

<style scoped>

</style>
