<template>
  <b-input-group :size="size">
    <b-input
      ref="myfilter"
      :value="value"
      @input="emitInput"
      type="search"
      id="filterInput"
      placeholder="Type to search"
    ></b-input>
    <b-input-group-append>
      <b-button :disabled="!this.value" @click="emitClear">Clear</b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  props: {
    value: String,
    size: String
  },
  methods: {
    emitInput(value) {
      this.$emit("input", value);
    },
    emitClear() {
      this.$emit("input", "");
    }
  }
};
</script>